import React from 'react';
import { Route, Switch } from 'react-router';
import BranchList from './BranchList';
import BranchEdit from './BranchEdit';
import OperatoryList from '../operatories/OperatoryList';
import OperatoryEdit from '../operatories/OperatoryEdit';

function BranchModule() {
  return (
    <Switch>
      <Route exact path='/cms/branches/' component={BranchList} />
      <Route exact path='/cms/branches/new' component={BranchEdit} />
      <Route exact path='/cms/branches/:id' component={BranchEdit} />

      {/* Operatories */}
      <Route exact path='/cms/branches/:id/operatories' component={OperatoryList} />
      <Route exact path='/cms/branches/:id/operatories/new' component={OperatoryEdit} />
      <Route exact path='/cms/branches/:id/operatories/:operatoryId' component={OperatoryEdit} />
    </Switch>
  );
}

export default BranchModule;
