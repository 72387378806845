import React from 'react';
import { Route, Switch } from 'react-router';
import AnswerEdit from '../answers/AnswerEdit';
import AnswerList from '../answers/AnswerList';
import SurveyEdit from '../surveys/SurveyEdit';
import SurveyList from '../surveys/SurveyList';
import SubscriberEdit from './SubscriberEdit';
import SubscriberList from './SubscriberList';

function SubscriberModule() {
  return (
    <Switch>
      <Route exact path='/cms/subscribers/' component={SubscriberList} />
      <Route exact path='/cms/subscribers/:id' component={SubscriberEdit} />

      {/* Survey */}
      <Route exact path='/cms/subscribers/:id/surveys' component={SurveyList} />
      <Route exact path='/cms/subscribers/:id/surveys/:sid' component={SurveyEdit} />

      {/* Answer */}
      <Route exact path='/cms/subscribers/:id/surveys/:sid/answers' component={AnswerList} />
      <Route exact path='/cms/subscribers/:id/surveys/:sid/answers/:aid' component={AnswerEdit} />
    </Switch>
  );
}

export default SubscriberModule;
