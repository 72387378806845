import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { Button } from '@rmwc/button';
import { Card } from '@rmwc/card';
import { CircularProgress } from '@rmwc/circular-progress';
import { SimpleDialog } from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';

import { Form, Formik, FormikHelpers as FormikActions } from 'formik';
import * as Yup from 'yup';

import { ErrorMessages } from '../../../constants/Strings';
import TextField from '../../../components/TextField';
import SelectField from '../../../components/SelectField';
import {
  useGetOperatory,
  useUpdateOperatory,
  useCreateOperatory,
  useGetDentrixOperatories,
  useGetDentrixProviders,
  useDeleteOperatory
} from '../../../lib/api/Operatory.hooks';
import CmsSnackbarQueue from '../../../lib/CmsSnackbarQueue';
import styled from '../../../styled-components';
import theme from '../../../constants/Theme';
import { Branch } from '../../../types/Branch';
import { useGetBranch } from '../../../lib/api/Branch.hooks';
import BreadCrumbs from '../../../components/Breadcrumbs';
import EmptyData from '../../../components/EmptyData';
import { useError } from '../../../lib/error.hook';

type OperatoryEditProps = RouteComponentProps<{ id: string; operatoryId: string }>;
type OperatoryFormType = {
  id: string;
  name: string;
  providerId: string;
  branch: Branch | null;
};

const OperatorySchema = Yup.object().shape({
  id: Yup.string().required(ErrorMessages.REQUIRED_OPERATORY_ID),
  providerId: Yup.string().required(ErrorMessages.REQUIRED_PROVIDER_ID)
});

const Container = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  margin: 8px 0;
`;

const StyledCard = styled(Card)`
  display: flex;
`;

const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`;

const FormContainer = styled(Form)`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  overflow: scroll;

  & > * {
    flex-shrink: 0;
  }
`;

const StyledTextField = styled(TextField)`
  margin-top: 10px;
  margin-bottom: 10px;

  & .mdc-text-field--textarea textarea {
    resize: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-start;

  & > * {
    margin: 5px;
  }
`;

const DeleteButton = styled(Button)`
  &&& {
    color: ${theme.destructiveColor};
  }
`;

const SelectContainer = styled.div`
  margin-bottom: 10px;
`;

function OperatoryEdit(props: OperatoryEditProps) {
  const { history, match } = props;
  const { id: branchId, operatoryId = 'new' } = match.params;
  const getOperatory = useGetOperatory(operatoryId);
  const getDentrixOperatories = useGetDentrixOperatories();
  const getDentrixProviders = useGetDentrixProviders();
  const updateOperatory = useUpdateOperatory(operatoryId);
  const deleteOperatory = useDeleteOperatory(operatoryId);
  const { get } = getOperatory;
  const getBranch = useGetBranch(branchId);
  const createOperatory = useCreateOperatory();
  const [dialog, setDialog] = useState(false);
  const { error: getError } = useError([getBranch.error, getOperatory.error, getDentrixOperatories.error, getDentrixProviders.error]);

  useError([updateOperatory.error, createOperatory.error, deleteOperatory.error]);

  useEffect(() => {
    if (operatoryId !== 'new') {
      get();
    }
  }, [get, operatoryId]);

  useEffect(() => {
    getBranch.get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBranch.get]);

  useEffect(() => {
    getDentrixOperatories.get({ locationId: branchId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDentrixOperatories.get, branchId]);

  useEffect(() => {
    getDentrixProviders.get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDentrixProviders.get]);

  useEffect(() => {
    if (updateOperatory.data && !updateOperatory.error && !updateOperatory.isLoading) {
      CmsSnackbarQueue.notify({
        title: `Operatory saved successfully.`,
        actions: [
          {
            label: 'VIEW',
            onClick: () => history.push(`/cms/branches/${branchId}/operatories/${updateOperatory.data!.id}`)
          }
        ]
      });

      history.push(`/cms/branches/${branchId}/operatories`);
    } else if (createOperatory.data && !createOperatory.error && !createOperatory.isLoading) {
      CmsSnackbarQueue.notify({
        title: `Operatory created successfully.`,
        actions: [
          {
            label: 'VIEW',
            onClick: () => history.push(`/cms/branches/${branchId}/operatories/${createOperatory.data!.id}`)
          }
        ]
      });
      history.push(`/cms/branches/${branchId}/operatories`);
    } else if (deleteOperatory.data && !deleteOperatory.error && !deleteOperatory.isLoading) {
      const { id, name } = deleteOperatory.data;
      CmsSnackbarQueue.notify({
        title: `Operatory ${id} (${name}) deleted successfully.`
      });
      history.push(`/cms/branches/${branchId}/operatories/`);
    }
  }, [
    branchId,
    history,
    updateOperatory.data,
    updateOperatory.error,
    updateOperatory.isLoading,
    createOperatory.data,
    createOperatory.error,
    createOperatory.isLoading,
    deleteOperatory.data,
    deleteOperatory.error,
    deleteOperatory.isLoading
  ]);

  const handleSubmit = async (values: OperatoryFormType, actions: FormikActions<OperatoryFormType>) => {
    actions.setSubmitting(true);
    if (operatoryId === 'new') {
      const newOperatory = {
        id: values.id,
        name: values.name,
        providerId: values.providerId,
        branch: getBranch.data
      };
      await createOperatory.create(newOperatory);
    } else {
      const operatoryObj = {
        id: values.id,
        name: values.name,
        providerId: values.providerId,
        branch: getBranch.data
      };
      await updateOperatory.update(operatoryObj);
    }
    actions.setSubmitting(false);
  };

  const handleDelete = async () => {
    await deleteOperatory.operatoryDelete();
  };

  const handleCancel = () => {
    history.goBack();
  };

  let initialValues;
  let ready = false;

  if (operatoryId === 'new') {
    initialValues = {
      id: '',
      name: '',
      providerId: '',
      branch: getBranch.data
    };
    ready = !getBranch.isLoading && !getDentrixOperatories.isLoading && !getDentrixProviders.isLoading;
  } else {
    initialValues = getError ? null : getOperatory.data;
    ready = !getOperatory.isLoading && !getBranch.isLoading && !getDentrixOperatories.isLoading && !getDentrixProviders.isLoading;
  }

  return (
    <Container>
      <BreadCrumbs
        crumbs={[
          { text: 'Branches', path: `/cms/branches` },
          { text: 'Operatories', path: `/cms/branches/${branchId}/operatories` },
          { text: `${operatoryId === 'new' ? 'Add' : 'Edit'}`, path: ``, disabled: true }
        ]}
      />
      <TopBar>
        <Typography use='headline5'>
          {`${operatoryId === 'new' ? 'Add' : 'Edit'}`}
          {getBranch.data && getBranch.data.name && ` ${getBranch.data.name}`} Operatory
        </Typography>
      </TopBar>
      <StyledCard>
        {!ready && (
          <Spinner>
            <CircularProgress size='large' />
          </Spinner>
        )}
        {ready && !initialValues && <EmptyData onBackClick={handleCancel} isNoData />}
        {ready && initialValues && (
          <Formik initialValues={initialValues} validationSchema={OperatorySchema} onSubmit={handleSubmit} enableReinitialize>
            {({ values, errors, touched, handleBlur, isSubmitting, setFieldValue, setFieldTouched }) => {
              const dentrixOperatoriesOptions = getDentrixOperatories.data.map(({ id, name }) => ({
                value: id,
                label: `${id} - ${name}`,
                name
              }));

              const dentrixProvidersOptions = getDentrixProviders.data.map(({ id, firstName, middleInitial, lastName }) => ({
                value: id,
                label: `${id} - ${firstName || ''} ${(middleInitial && `${middleInitial}.`) || ''} ${lastName || ''}`
              }));

              return (
                <FormContainer>
                  <SelectContainer>
                    <SelectField
                      defaultValue={dentrixOperatoriesOptions.find(dentrixOperatoryOption => dentrixOperatoryOption.value === values.id)}
                      options={dentrixOperatoriesOptions}
                      onChange={(dentrixOperatoryOption: { value: string; label: string; name: string }) => {
                        setFieldValue('name', dentrixOperatoryOption.name);
                        setFieldValue('id', dentrixOperatoryOption.value);
                      }}
                      onBlur={(e: React.ChangeEvent) => {
                        setFieldTouched('id', true, false);
                        return handleBlur(e);
                      }}
                      placeholder='Select Operatory ID from Dentrix'
                      label='Operatory ID'
                      changeOptionLabel='label'
                      changeOptionValue='value'
                      invalid={touched.id && errors.id}
                      helpText={touched.id && errors.id}
                      isDisabled={operatoryId !== 'new'}
                    />
                  </SelectContainer>
                  <StyledTextField outlined type='text' name='id' label='Operatory ID' value={values.id} disabled />
                  <StyledTextField outlined type='text' name='name' label='Operatory Name' value={values.name} disabled />
                  <SelectContainer>
                    <SelectField
                      name='providerId'
                      defaultValue={dentrixProvidersOptions.find(
                        dentrixProviderOption => dentrixProviderOption.value === values.providerId
                      )}
                      options={dentrixProvidersOptions}
                      onChange={(dentrixOperatoryOption: { value: string; label: string; name: string }) => {
                        setFieldValue('providerId', dentrixOperatoryOption.value);
                      }}
                      onBlur={handleBlur}
                      placeholder='Select Provider ID from Dentrix'
                      label='Provider ID'
                      changeOptionLabel='label'
                      changeOptionValue='value'
                      invalid={touched.providerId && errors.providerId}
                      helpText={touched.providerId && errors.providerId}
                    />
                  </SelectContainer>
                  <ButtonContainer>
                    <Buttons>
                      <Button
                        unelevated
                        type='submit'
                        label='Save'
                        disabled={isSubmitting}
                        icon={isSubmitting ? <CircularProgress /> : null}
                      />
                      <Button theme='secondary' type='button' label='Cancel' onClick={handleCancel} />
                    </Buttons>
                    {operatoryId !== 'new' && <DeleteButton type='button' label='Delete' onClick={() => setDialog(true)} />}
                  </ButtonContainer>
                </FormContainer>
              );
            }}
          </Formik>
        )}
      </StyledCard>
      <SimpleDialog
        title='Delete Operatory'
        body='Are you sure you want to delete this Operatory?'
        open={dialog}
        onClose={async e => {
          if (e.detail.action === 'accept') {
            await handleDelete();
          }
          setDialog(false);
        }}
        acceptLabel='Delete'
      />
    </Container>
  );
}

export default withRouter(OperatoryEdit);
