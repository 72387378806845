import { useCallback } from 'react';
import Defaults from '../../constants/Defaults';
import { Operatory } from '../../types/Operatory';
import { HttpMethod } from './Api';
import { useApiGet, useApiMutation } from './Api.hooks';

export function useGetOperatory(operatoryId: string) {
  return useApiGet<Operatory | null>(null, 'operatories/' + operatoryId);
}

export type DentrixOperatory = {
  id: string;
  name: string;
};

export function useGetDentrixOperatories() {
  const { get: originalGet, data, ...rest } = useApiGet<DentrixOperatory[]>([], 'dentrix/operatories');

  const get = useCallback(
    async (params?: { locationId: string }) => {
      await originalGet({ ...params });
    },
    [originalGet]
  );
  return { ...rest, data, get };
}

export type DentrixProvider = {
  id?: string;
  firstName: string;
  middleInitial?: string;
  lastName: string;
  shortName: string;
};

export function useGetDentrixProviders() {
  return useApiGet<DentrixProvider[]>([], 'dentrix/providers');
}

export function useGetOperatoriesByPage() {
  const { get: originalGet, data, ...rest } = useApiGet<[Operatory[], number]>([[], 0], 'operatories/');

  const get = useCallback(
    async (params?: { page?: number; query?: string; sort?: string; limit?: number; branchId?: string }) => {
      const defaults = {
        page: 0,
        limit: Defaults.LIST_PAGE_SIZE
      };
      await originalGet({ ...defaults, ...params });
    },
    [originalGet]
  );

  const [responseData, count] = data;
  return { ...rest, data: responseData, count, get };
}

export type OperatoryUpdateDTO = {
  name: string;
  providerId: string;
};

export function useUpdateOperatory(operatoryId: string) {
  const { mutate, ...rest } = useApiMutation<Operatory, OperatoryUpdateDTO>(null, HttpMethod.PATCH, 'operatories/' + operatoryId);

  const update = useCallback(
    async (operatory: OperatoryUpdateDTO) => {
      await mutate(operatory);
    },
    [mutate]
  );

  return { ...rest, update };
}

export type OperatoryCreateDTO = {
  id: string;
  name: string;
  providerId: string;
};

export function useCreateOperatory() {
  const { mutate, ...rest } = useApiMutation<Operatory, OperatoryCreateDTO>(null, HttpMethod.POST, 'operatories/');

  const create = useCallback(
    async (operatory: OperatoryCreateDTO) => {
      await mutate(operatory);
    },
    [mutate]
  );

  return { ...rest, create };
}

export function useDeleteOperatory(operatoryId: string) {
  const { mutate, ...rest } = useApiMutation<Operatory, null>(null, HttpMethod.DELETE, 'operatories/' + operatoryId);

  const operatoryDelete = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return { ...rest, operatoryDelete };
}
