import React from 'react';

import { Button } from '@rmwc/button';
import { Icon } from '@rmwc/icon';
import { Typography } from '@rmwc/typography';

import styled from '../styled-components';
import theme from '../constants/Theme';

const StyledEmptyDataContainer = styled.div<{ color?: string }>`
  padding: 48px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledIcon = styled(Icon)<{ color?: string }>`
  ${({ color }) => `
    color: ${color || 'black'};
    font-size: 48px !important;
  `}
`;

const StyledTypography = styled(Typography)<{ color?: string }>`
  ${({ color }) => `
    color: ${color || 'black'};
  `}
`;

type EmptyDataProps = {
  isNoData?: boolean;
  onBackClick?: () => void;
};

function EmptyData(props: EmptyDataProps) {
  const { isNoData = false, onBackClick } = props;

  return (
    <StyledEmptyDataContainer>
      <StyledIcon icon='error' color={theme.grey} />
      <StyledTypography use='headline5' color={theme.grey}>
        {isNoData ? 'Data cannot be retrieved.' : 'No records.'}
      </StyledTypography>
      <StyledTypography use='subtitle2' color={theme.grey}>
        {isNoData ? "It's either the data is non-existent, or it was previously deleted." : 'There are no records available.'}
      </StyledTypography>
      {isNoData && onBackClick && (
        <Button onClick={onBackClick} style={{ marginTop: 16 }}>
          Go Back
        </Button>
      )}
    </StyledEmptyDataContainer>
  );
}

export default EmptyData;
