import React from 'react';
import { Route, Switch } from 'react-router';

import RankingEdit from './RankingEdit';
import RankingList from './RankingList';

function RankingModule() {
  return (
    <Switch>
      <Route exact path='/cms/rankings/' component={RankingList} />
      <Route exact path='/cms/rankings/:id' component={RankingEdit} />
    </Switch>
  );
}

export default RankingModule;
