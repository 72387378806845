import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { Button } from '@rmwc/button';
import { Card } from '@rmwc/card';
import { CircularProgress } from '@rmwc/circular-progress';
import { SimpleDialog } from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';

import { Form, Formik, FormikHelpers as FormikActions } from 'formik';
import * as Yup from 'yup';

import { ErrorMessages } from '../../../constants/Strings';
import TextField from '../../../components/TextField';
import CmsSnackbarQueue from '../../../lib/CmsSnackbarQueue';
import styled from '../../../styled-components';
import theme from '../../../constants/Theme';
import { Quiz } from '../../../types/Quiz';
import { useGetQuiz, useUpdateQuiz, useCreateQuiz, useDeleteQuiz } from '../../../lib/api/Quiz.hooks';
import SelectField from '../../../components/SelectField';
import { QuizTypeEnum, QuizTypeOption, QUIZ_TYPE_OPTIONS } from '../../../enums/QuizType.enum';
import BreadCrumbs from '../../../components/Breadcrumbs';
import EmptyData from '../../../components/EmptyData';
import { useError } from '../../../lib/error.hook';

type QuizEditProps = RouteComponentProps<{ id: string }>;
type QuizFormType = {
  id: number | string;
  name: string;
  type: QuizTypeEnum;
};

const QuizSchema = Yup.object().shape({
  name: Yup.string().required(ErrorMessages.REQUIRED_NAME),
  type: Yup.mixed<QuizTypeEnum>().oneOf(Object.values(QuizTypeEnum) as QuizTypeEnum[])
});

const Container = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  margin: 8px 0;
`;

const StyledCard = styled(Card)`
  display: flex;
`;

const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`;

const FormContainer = styled(Form)`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  overflow: scroll;

  & > * {
    flex-shrink: 0;
  }
`;

const StyledTextField = styled(TextField)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-start;

  & > * {
    margin: 5px;
  }
`;

const DeleteButton = styled(Button)`
  &&& {
    color: ${theme.destructiveColor};
  }
`;

const SelectContainer = styled.div`
  margin-bottom: 10px;
`;

function QuizEdit(props: QuizEditProps) {
  const { history, match } = props;
  const quizId = match.params.id;
  const getQuiz = useGetQuiz(quizId);
  const updateQuiz = useUpdateQuiz(quizId);
  const { get } = getQuiz;
  const createQuiz = useCreateQuiz();
  const deleteQuiz = useDeleteQuiz(quizId);
  const [dialog, setDialog] = useState(false);
  const { error: getError } = useError([getQuiz.error]);

  useError([updateQuiz.error, createQuiz.error, deleteQuiz.error]);

  useEffect(() => {
    if (quizId !== 'new') {
      get();
    }
  }, [get, quizId]);

  useEffect(() => {
    if (updateQuiz.data && !updateQuiz.error && !updateQuiz.isLoading) {
      CmsSnackbarQueue.notify({
        title: `${updateQuiz.data.name} saved successfully.`,
        actions: [
          {
            label: 'VIEW',
            onClick: () => history.push('/cms/quizzes/' + updateQuiz.data!.id)
          }
        ]
      });

      history.push('/cms/quizzes');
    } else if (createQuiz.data && !createQuiz.error && !createQuiz.isLoading) {
      CmsSnackbarQueue.notify({
        title: `${createQuiz.data.name} created successfully.`,
        actions: [
          {
            label: 'VIEW',
            onClick: () => history.push('/cms/quizzes/' + createQuiz.data!.id)
          }
        ]
      });
      history.push('/cms/quizzes');
    } else if (deleteQuiz.data && !deleteQuiz.error && !deleteQuiz.isLoading) {
      CmsSnackbarQueue.notify({
        title: `${deleteQuiz.data.name} deleted successfully.`
      });
      history.push('/cms/quizzes');
    }
  }, [
    history,
    updateQuiz.data,
    updateQuiz.error,
    updateQuiz.isLoading,
    createQuiz.data,
    createQuiz.error,
    createQuiz.isLoading,
    deleteQuiz.data,
    deleteQuiz.error,
    deleteQuiz.isLoading
  ]);

  const handleSubmit = async (values: QuizFormType, actions: FormikActions<Quiz | { id: string; name: string; type: QuizTypeEnum }>) => {
    actions.setSubmitting(true);
    if (quizId === 'new') {
      let newQuiz = {
        name: values.name,
        type: values.type
      };
      await createQuiz.create(newQuiz);
    } else {
      const quizObj = {
        name: values.name,
        type: values.type
      };
      await updateQuiz.update(quizObj);
    }
    actions.setSubmitting(false);
  };

  const handleDelete = async () => {
    await deleteQuiz.quizDelete();
  };

  const handleCancel = () => {
    history.goBack();
  };

  let initialValues;
  let ready = false;

  if (quizId === 'new') {
    initialValues = { id: 'new', name: '', type: QuizTypeEnum.INITIAL };
    ready = true;
  } else {
    initialValues = getError ? null : getQuiz.data;
    ready = !getQuiz.isLoading;
  }

  return (
    <Container>
      <BreadCrumbs crumbs={[{ text: 'Quizzes', path: '/cms/quizzes' }, { text: `${quizId === 'new' ? 'Add' : 'Edit'}`, path: '' }]} />
      <TopBar>
        <Typography use='headline5'>Quiz</Typography>
      </TopBar>
      <StyledCard>
        {!ready && (
          <Spinner>
            <CircularProgress size='large' />
          </Spinner>
        )}
        {ready && !initialValues && <EmptyData onBackClick={handleCancel} isNoData />}
        {ready && initialValues && (
          <Formik
            initialValues={initialValues}
            validationSchema={QuizSchema}
            validateOnBlur={true}
            onSubmit={(value, action) => {
              handleSubmit(value, action);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, isSubmitting, setFieldValue }) => (
              <FormContainer>
                <StyledTextField outlined name='id' label='ID' value={values.id} disabled />
                <StyledTextField
                  outlined
                  type='text'
                  name='name'
                  label='Name'
                  onChange={handleChange}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('name', values.name.trim());
                    handleBlur(e);
                  }}
                  value={values.name}
                  invalid={touched.name && errors.name}
                  helpText={{ persistent: true, validationMsg: true, children: touched.name && errors.name }}
                />
                <SelectContainer>
                  <SelectField
                    isMulti={false}
                    defaultValue={QUIZ_TYPE_OPTIONS.find(v => v.value === values.type)}
                    name='type'
                    options={QUIZ_TYPE_OPTIONS}
                    onChange={(quizType: QuizTypeOption) => setFieldValue('type', quizType.value)}
                    onBlur={handleBlur}
                    placeholder='Select Quiz Type'
                    label='Quiz Type'
                    changeOptionLabel='label'
                    changeOptionValue='value'
                    invalid={touched.type && errors.type}
                    helpText={touched.type && errors.type}
                  />
                </SelectContainer>
                <ButtonContainer>
                  <Buttons>
                    <Button
                      unelevated
                      type='submit'
                      label='Save'
                      disabled={isSubmitting}
                      icon={isSubmitting ? <CircularProgress /> : null}
                    />
                    <Button theme='secondary' type='button' label='Cancel' onClick={handleCancel} />
                  </Buttons>
                  {quizId !== 'new' && <DeleteButton type='button' label='Delete' onClick={() => setDialog(true)} />}
                </ButtonContainer>
              </FormContainer>
            )}
          </Formik>
        )}
      </StyledCard>
      <SimpleDialog
        title='Delete Quiz'
        body='Are you sure you want to delete this quiz?'
        open={dialog}
        onClose={async e => {
          if (e.detail.action === 'accept') {
            await handleDelete();
          }
          setDialog(false);
        }}
        acceptLabel='Delete'
      />
    </Container>
  );
}

export default withRouter(QuizEdit);
