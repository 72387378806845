import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { Button } from '@rmwc/button';
import { Card } from '@rmwc/card';
import { CircularProgress } from '@rmwc/circular-progress';
import { SimpleDialog } from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';

import { Form, Formik, FormikHelpers as FormikActions } from 'formik';
import * as Yup from 'yup';

import { ErrorMessages } from '../../../constants/Strings';
import TextField from '../../../components/TextField';
import CmsSnackbarQueue from '../../../lib/CmsSnackbarQueue';
import styled from '../../../styled-components';
import theme from '../../../constants/Theme';
import { Location } from '../../../types/Branch';
import { useGetLocations, useGetBranch, useUpdateBranch, useCreateBranch, useDeleteBranch } from '../../../lib/api/Branch.hooks';
import SelectField from '../../../components/SelectField';
import BreadCrumbs from '../../../components/Breadcrumbs';
import EmptyData from '../../../components/EmptyData';
import { useError } from '../../../lib/error.hook';
import { Checkbox } from '@rmwc/checkbox';

type BranchEditProps = RouteComponentProps<{ id: string }>;
type BranchFormType = {
  id: string;
  name: string;
  selectOperatory: boolean;
};

const BranchSchema = Yup.object().shape({
  id: Yup.string().required(ErrorMessages.REQUIRED_BRANCH_ID),
  name: Yup.string().required(ErrorMessages.REQUIRED_BRANCH_NAME)
});

const Container = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  margin: 8px 0;
`;

const StyledCard = styled(Card)`
  display: flex;
`;

const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`;

const FormContainer = styled(Form)`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  overflow: scroll;

  & > * {
    flex-shrink: 0;
  }
`;

const StyledTextField = styled(TextField)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-start;

  & > * {
    margin: 5px;
  }
`;

const DeleteButton = styled(Button)`
  &&& {
    color: ${theme.destructiveColor};
  }
`;

const SelectContainer = styled.div`
  margin-bottom: 10px;
`;

function BranchEdit(props: BranchEditProps) {
  const { history, match } = props;
  const branchId = match.params.id || 'new';
  const getBranch = useGetBranch(branchId);
  const updateBranch = useUpdateBranch(branchId);
  const { get } = getBranch;
  const createBranch = useCreateBranch();
  const deleteBranch = useDeleteBranch(branchId);
  const getLocations = useGetLocations();

  const [dialog, setDialog] = useState(false);
  const { error: getError } = useError([getBranch.error, getLocations.error]);

  useError([updateBranch.error, createBranch.error, deleteBranch.error]);

  useEffect(() => {
    if (branchId !== 'new') {
      get();
    }
  }, [get, branchId]);

  useEffect(() => {
    getLocations.get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLocations.get]);

  useEffect(() => {
    if (updateBranch.data && !updateBranch.error && !updateBranch.isLoading) {
      CmsSnackbarQueue.notify({
        title: `${updateBranch.data.name} saved successfully.`,
        actions: [
          {
            label: 'VIEW',
            onClick: () => history.push('/cms/branches/' + updateBranch.data!.id)
          }
        ]
      });

      history.push('/cms/branches');
    } else if (createBranch.data && !createBranch.error && !createBranch.isLoading) {
      CmsSnackbarQueue.notify({
        title: `${createBranch.data.name} created successfully.`,
        actions: [
          {
            label: 'VIEW',
            onClick: () => history.push('/cms/branches/' + createBranch.data!.id)
          }
        ]
      });
      history.push('/cms/branches');
    } else if (deleteBranch.data && !deleteBranch.error && !deleteBranch.isLoading) {
      CmsSnackbarQueue.notify({
        title: `${deleteBranch.data.name} deleted successfully.`
      });
      history.push('/cms/branches');
    }
  }, [
    history,
    updateBranch.data,
    updateBranch.error,
    updateBranch.isLoading,
    createBranch.data,
    createBranch.error,
    createBranch.isLoading,
    deleteBranch.data,
    deleteBranch.error,
    deleteBranch.isLoading
  ]);

  const handleSubmit = async (values: BranchFormType, actions: FormikActions<BranchFormType>) => {
    actions.setSubmitting(true);
    if (branchId === 'new') {
      let newBranch = {
        id: values.id,
        name: values.name,
        selectOperatory: values.selectOperatory
      };
      await createBranch.branchCreate(newBranch);
    } else {
      const branchObj = {
        id: values.id,
        name: values.name,
        selectOperatory: values.selectOperatory
      };
      await updateBranch.update(branchObj);
    }
    actions.setSubmitting(false);
  };

  const handleDelete = async () => {
    await deleteBranch.branchDelete();
  };

  const handleCancel = () => {
    history.goBack();
  };

  let initialValues;
  let ready = false;

  if (branchId === 'new') {
    initialValues = { id: '', name: '', selectOperatory: false };
    ready = !getLocations.isLoading;
  } else {
    initialValues = getError ? null : getBranch.data;
    ready = !getBranch.isLoading && !getLocations.isLoading;
  }

  return (
    <Container>
      <BreadCrumbs crumbs={[{ text: 'Branches', path: '/cms/branches' }, { text: `${branchId === 'new' ? 'Add' : 'Edit'}`, path: '' }]} />
      <TopBar>
        <Typography use='headline5'>Branch</Typography>
      </TopBar>
      <StyledCard>
        {!ready && (
          <Spinner>
            <CircularProgress size='large' />
          </Spinner>
        )}
        {ready && !initialValues && <EmptyData onBackClick={handleCancel} isNoData />}
        {ready && initialValues && (
          <Formik
            initialValues={initialValues}
            validationSchema={BranchSchema}
            enableReinitialize
            onSubmit={(value, action) => {
              handleSubmit(value, action);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, isSubmitting, setFieldValue, setFieldTouched }) => (
              <FormContainer>
                <SelectContainer>
                  <SelectField
                    isClearable
                    isSearchable
                    defaultValue={getLocations.data.find(location => location.id === values.id)}
                    options={getLocations.data}
                    onChange={(branch: Location) => {
                      setFieldValue('name', branch.name);
                      setFieldValue('id', branch.id);
                    }}
                    onBlur={(e: React.ChangeEvent) => {
                      setFieldTouched('id', true, false);
                      return handleBlur(e);
                    }}
                    placeholder='Select a Location'
                    label='Dentrix Locations'
                    changeOptionLabel='name'
                    changeOptionValue='id'
                    invalid={touched.id && errors.id}
                    helpText={touched.id && errors.id}
                    isDisabled={branchId !== 'new'}
                  />
                </SelectContainer>
                <StyledTextField outlined name='id' label='Branch ID' value={values.id} disabled />
                <StyledTextField outlined name='name' label='Branch Name' value={values.name} disabled />
                <Checkbox
                  name='selectOperatory'
                  label='Allow users to select operatory?'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.selectOperatory}
                />
                <ButtonContainer>
                  <Buttons>
                    <Button
                      unelevated
                      type='submit'
                      label='Save'
                      disabled={isSubmitting}
                      icon={isSubmitting ? <CircularProgress /> : null}
                    />
                    <Button theme='secondary' type='button' label='Cancel' onClick={handleCancel} />
                  </Buttons>
                  {branchId !== 'new' && <DeleteButton type='button' label='Delete' onClick={() => setDialog(true)} />}
                </ButtonContainer>
              </FormContainer>
            )}
          </Formik>
        )}
      </StyledCard>
      <SimpleDialog
        title='Delete Branch'
        body='Are you sure you want to delete this branch?'
        open={dialog}
        onClose={async e => {
          if (e.detail.action === 'accept') {
            await handleDelete();
          }
          setDialog(false);
        }}
        acceptLabel='Delete'
      />
    </Container>
  );
}

export default withRouter(BranchEdit);
