import React from 'react';
import { Route, Switch } from 'react-router';

import UserEdit from './UserEdit';
import UserList from './UserList';

function UserModule() {
  return (
    <Switch>
      <Route exact path='/cms/users/' component={UserList}/>
      <Route exact path='/cms/users/:id' component={UserEdit}/>
      <Route exact path='/cms/users/new' component={UserEdit}/>
    </Switch>
  );
}

export default UserModule;
