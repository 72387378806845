import React from 'react';
import { Route, Switch } from 'react-router';

import ServiceList from './ServiceList';
import ServiceEdit from './ServiceEdit';
import ServiceScheduleList from './ServiceScheduleList';
import ServiceScheduleView from './ServiceScheduleView';
import ServiceScheduleCreate from './ServiceScheduleCreate';

function ServiceModule() {
  return (
    <Switch>
      <Route exact path='/cms/services/' component={ServiceList} />
      <Route exact path='/cms/services/:id' component={ServiceEdit} />
      <Route exact path='/cms/services/:id/schedules/' component={ServiceScheduleList} />
      <Route exact path='/cms/services/:id/schedules/new' component={ServiceScheduleCreate} />
      <Route exact path='/cms/services/:id/schedules/:ssid' component={ServiceScheduleView} />
    </Switch>
  );
}

export default ServiceModule;
