import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { Button } from '@rmwc/button';
import { Card } from '@rmwc/card';
import { CircularProgress } from '@rmwc/circular-progress';
import { Typography } from '@rmwc/typography';

import { Form, Formik, FormikHelpers as FormikActions } from 'formik';
import * as Yup from 'yup';

import styled from '../../../styled-components';
import TextField from '../../../components/TextField';
import BreadCrumbs from '../../../components/Breadcrumbs';
import SelectField from '../../../components/SelectField';

import { ErrorMessages } from '../../../constants/Strings';
import { useError } from '../../../lib/error.hook';
import CmsSnackbarQueue from '../../../lib/CmsSnackbarQueue';

import { useGetService } from '../../../lib/api/Service.hooks';
import { useCreateServiceSchedule } from '../../../lib/api/ServiceSchedule.hooks';
import { useGetSchedulesByPage } from '../../../lib/api/Schedule.hooks';
import { formatTime } from '../../../constants/Helpers';

type ServiceScheduleCreateProps = RouteComponentProps<{ id: string; ssid: string }>;
type ServiceScheduleFormType = {
  id: string;
  serviceId: string;
  scheduleId?: number;
};

const ServiceScheduleSchema = Yup.object().shape({
  scheduleId: Yup.string().required(ErrorMessages.REQUIRED_SERVICE_SCHEDULE)
});

const Container = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  margin: 8px 0;
`;

const StyledCard = styled(Card)`
  display: flex;
`;

const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`;

const FormContainer = styled(Form)`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  overflow: scroll;

  & > * {
    flex-shrink: 0;
  }
`;

const StyledTextField = styled(TextField)`
  margin-top: 10px;
  margin-bottom: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-start;

  & > * {
    margin: 5px;
  }
`;

const ServiceScheduleCreate = (props: ServiceScheduleCreateProps) => {
  const { history, match } = props;
  const serviceId = match.params.id;

  const getService = useGetService(serviceId);
  const getSchedules = useGetSchedulesByPage();
  useError([getService.error, getSchedules.error]);

  const createServiceSchedule = useCreateServiceSchedule();
  useError([createServiceSchedule.error]);

  useEffect(() => {
    getService.get();
    getSchedules.get({
      page: 0,
      limit: 1000
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getService.get, getSchedules.get]);

  useEffect(() => {
    if (createServiceSchedule.data && !createServiceSchedule.error && !createServiceSchedule.isLoading) {
      CmsSnackbarQueue.notify({
        title: `Service Schedule ${createServiceSchedule.data.id} created successfully.`,
        actions: [
          {
            label: 'VIEW',
            onClick: () => history.push(`/cms/services/${serviceId}/schedules/${createServiceSchedule.data!.id}`)
          }
        ]
      });
      history.push(`/cms/services/${serviceId}/schedules`);
    }
  }, [history, serviceId, createServiceSchedule.data, createServiceSchedule.error, createServiceSchedule.isLoading]);

  const handleSubmit = async (values: ServiceScheduleFormType, actions: FormikActions<ServiceScheduleFormType>) => {
    if (!values.scheduleId) {
      return;
    }

    actions.setSubmitting(true);
    await createServiceSchedule.create({
      serviceId: values.serviceId,
      scheduleId: values.scheduleId
    });
    actions.setSubmitting(false);
  };

  const handleCancel = () => {
    history.goBack();
  };

  let initialValues;
  let ready = false;

  initialValues = { id: 'new', serviceId, scheduleId: undefined };
  ready = !getSchedules.isLoading;

  return (
    <Container>
      <BreadCrumbs
        crumbs={[
          { text: 'Services', path: `/cms/services` },
          { text: 'Schedules', path: `/cms/services/${serviceId}/schedules` },
          { text: 'Add', path: '' }
        ]}
      />
      <TopBar>
        <Typography use='headline5'>Service Schedule</Typography>
      </TopBar>
      <StyledCard>
        {!ready && (
          <Spinner>
            <CircularProgress size='large' />
          </Spinner>
        )}
        {ready && initialValues && (
          <Formik
            initialValues={initialValues as ServiceScheduleFormType}
            validationSchema={ServiceScheduleSchema}
            validateOnBlur={true}
            onSubmit={(value, action) => {
              handleSubmit(value, action);
            }}
          >
            {({ values, errors, touched, handleBlur, isSubmitting, setFieldValue }) => {
              const scheduleOptions = getSchedules.data.map(schedule => ({
                value: schedule.id,
                label: `[ID: ${schedule.id}] ${schedule.day} | ${formatTime(schedule.start)} — ${formatTime(schedule.end)}`
              }));

              return (
                <FormContainer>
                  <StyledTextField outlined name='id' label='ID' value={values.id} disabled />
                  <StyledTextField outlined name='serviceId' label='Service ID' value={values.serviceId} disabled />
                  <SelectField
                    isMulti={false}
                    defaultValue={scheduleOptions.find(scheduleOption => scheduleOption.value === values.scheduleId)}
                    name='scheduleId'
                    options={scheduleOptions}
                    onChange={(scheduleOption: { value: string; label: string }) =>
                      setTimeout(() => setFieldValue('scheduleId', scheduleOption.value, true))
                    }
                    onBlur={handleBlur}
                    placeholder='Select a Schedule'
                    label='Schedule'
                    changeOptionLabel='label'
                    changeOptionValue='value'
                    invalid={touched.scheduleId && errors.scheduleId}
                    helpText={touched.scheduleId && errors.scheduleId}
                  />
                  <ButtonContainer>
                    <Buttons>
                      <Button
                        unelevated
                        type='submit'
                        label='Save'
                        disabled={isSubmitting}
                        icon={isSubmitting ? <CircularProgress /> : null}
                      />
                      <Button theme='secondary' type='button' label='Cancel' onClick={handleCancel} />
                    </Buttons>
                  </ButtonContainer>
                </FormContainer>
              );
            }}
          </Formik>
        )}
      </StyledCard>
    </Container>
  );
};

export default withRouter(ServiceScheduleCreate);
