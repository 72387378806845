import React from 'react';
import { Route, Switch } from 'react-router';
import ChoiceEdit from '../choices/ChoiceEdit';
import ChoiceList from '../choices/ChoiceList';
import QuestionEdit from '../questions/QuestionEdit';
import QuestionList from '../questions/QuestionList';
import QuizEdit from './QuizEdit';
import QuizList from './QuizList';

function QuizModule() {
  return (
    <Switch>
      <Route exact path='/cms/quizzes/' component={QuizList} />
      <Route exact path='/cms/quizzes/:id' component={QuizEdit} />
      <Route exact path='/cms/quizzes/new' component={QuizEdit} />

      {/* Questions */}
      <Route exact path='/cms/quizzes/:id/questions' component={QuestionList} />
      <Route exact path='/cms/quizzes/:id/questions/:qid' component={QuestionEdit} />
      <Route exact path='/cms/quizzes/:id/questions/new' component={QuestionEdit} />

      {/* Choices */}
      <Route exact path='/cms/quizzes/:id/questions/:qid/choices' component={ChoiceList} />
      <Route exact path='/cms/quizzes/:id/questions/:qid/choices/:cid' component={ChoiceEdit} />
      <Route exact path='/cms/quizzes/:id/questions/:qid/choices/new' component={ChoiceEdit} />
    </Switch>
  );
}

export default QuizModule;
