import { useEffect } from 'react';
import CmsSnackbarQueue from './CmsSnackbarQueue';

export function useError(errors: (Error | null)[]) {
  const error = errors.find(error => !!error);

  useEffect(() => {
    if (error && error.message) {
      const queue = CmsSnackbarQueue.messages && CmsSnackbarQueue.messages.array;
      queue.splice(0);
      if (queue.length <= 1) {
        CmsSnackbarQueue.notify({
          title: `${error.message}`,
          icon: 'error'
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, errors);

  return { error };
}
