import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Form, Formik, FormikHelpers as FormikActions } from 'formik';
import { capitalize } from 'lodash';
import * as Yup from 'yup';

import { Button } from '@rmwc/button';
import { Card } from '@rmwc/card';
import { CircularProgress } from '@rmwc/circular-progress';
import { SimpleDialog } from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';

import { ErrorMessages } from '../../../constants/Strings';
import TextField from '../../../components/TextField';
import CmsSnackbarQueue from '../../../lib/CmsSnackbarQueue';
import styled from '../../../styled-components';
import theme from '../../../constants/Theme';
import { Schedule } from '../../../types/Schedule';
import { useGetSchedule, useUpdateSchedule, useCreateSchedule, useDeleteSchedule } from '../../../lib/api/Schedule.hooks';
import SelectField from '../../../components/SelectField';
import BreadCrumbs from '../../../components/Breadcrumbs';
import EmptyData from '../../../components/EmptyData';
import { useError } from '../../../lib/error.hook';
import { addMinutesToTime, DAYS_OF_WEEK, formatToDBTime } from '../../../constants/Helpers';
import BasicTimePicker from '../../../components/BasicTimePicker';

type ScheduleEditProps = RouteComponentProps<{ id: string }>;
type ScheduleFormType = {
  id: number | string;
  day: string;
  start: string;
  end: string;
};

const ScheduleSchema = Yup.object().shape({
  day: Yup.string()
    .required(ErrorMessages.REQUIRED_DAY)
    .oneOf(DAYS_OF_WEEK, ErrorMessages.INVALID_DAY),
  start: Yup.string().required(ErrorMessages.REQUIRED_START),
  end: Yup.string().required(ErrorMessages.REQUIRED_END)
});

const Container = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  margin: 8px 0;
`;

const StyledCard = styled(Card)`
  display: flex;
`;

const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`;

const FormContainer = styled(Form)`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  overflow: scroll;

  & > * {
    flex-shrink: 0;
  }
`;

const StyledTextField = styled(TextField)`
  margin-top: 10px;
  margin-bottom: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-start;

  & > * {
    margin: 5px;
  }
`;

const DeleteButton = styled(Button)`
  &&& {
    color: ${theme.destructiveColor};
  }
`;

function ScheduleEdit(props: ScheduleEditProps) {
  const { history, match } = props;
  const scheduleId = match.params.id;
  const getSchedule = useGetSchedule(scheduleId);
  const updateSchedule = useUpdateSchedule(scheduleId);
  const { get } = getSchedule;
  const createSchedule = useCreateSchedule();
  const deleteSchedule = useDeleteSchedule(scheduleId);
  const [dialog, setDialog] = useState(false);

  const { error: getError } = useError([getSchedule.error]);

  useError([updateSchedule.error, createSchedule.error, deleteSchedule.error]);

  useEffect(() => {
    if (scheduleId !== 'new') {
      get();
    }
  }, [get, scheduleId]);

  useEffect(() => {
    if (updateSchedule.data && !updateSchedule.error && !updateSchedule.isLoading) {
      CmsSnackbarQueue.notify({
        title: `Schedule ${updateSchedule.data.id} saved successfully.`,
        actions: [
          {
            label: 'VIEW',
            onClick: () => history.push('/cms/schedules/' + updateSchedule.data!.id)
          }
        ]
      });

      history.push('/cms/schedules');
    } else if (createSchedule.data && !createSchedule.error && !createSchedule.isLoading) {
      CmsSnackbarQueue.notify({
        title: `Schedule ${createSchedule.data.id} created successfully.`,
        actions: [
          {
            label: 'VIEW',
            onClick: () => history.push('/cms/schedules/' + createSchedule.data!.id)
          }
        ]
      });
      history.push('/cms/schedules');
    } else if (deleteSchedule.data && !deleteSchedule.error && !deleteSchedule.isLoading) {
      CmsSnackbarQueue.notify({
        title: `Schedule ${deleteSchedule.data.id} deleted successfully.`
      });
      history.push('/cms/schedules');
    }
  }, [
    history,
    updateSchedule.data,
    updateSchedule.error,
    updateSchedule.isLoading,
    createSchedule.data,
    createSchedule.error,
    createSchedule.isLoading,
    deleteSchedule.data,
    deleteSchedule.error,
    deleteSchedule.isLoading
  ]);

  const handleSubmit = async (
    values: ScheduleFormType,
    actions: FormikActions<Schedule | { id: string; day: string; start: string; end: string }>
  ) => {
    actions.setSubmitting(true);
    if (scheduleId === 'new') {
      let newSchedule = {
        day: values.day,
        start: values.start,
        end: values.end
      };
      await createSchedule.create(newSchedule);
    } else {
      const scheduleObj = {
        day: values.day,
        start: values.start,
        end: values.end
      };
      await updateSchedule.update(scheduleObj);
    }
    actions.setSubmitting(false);
  };

  const handleDelete = async () => {
    await deleteSchedule.scheduleDelete();
  };

  const handleCancel = () => {
    history.goBack();
  };

  let initialValues;
  let ready = false;

  if (scheduleId === 'new') {
    initialValues = { id: 'new', day: DAYS_OF_WEEK[0], start: '08:00:00', end: '08:15:00' };
    ready = true;
  } else {
    initialValues = getError ? null : getSchedule.data;
    ready = !getSchedule.isLoading;
  }

  return (
    <Container>
      <BreadCrumbs
        crumbs={[{ text: 'Schedules', path: '/cms/schedules' }, { text: `${scheduleId === 'new' ? 'Add' : 'Edit'}`, path: '' }]}
      />
      <TopBar>
        <Typography use='headline5'>Schedule</Typography>
      </TopBar>
      <StyledCard>
        {!ready && (
          <Spinner>
            <CircularProgress size='large' />
          </Spinner>
        )}
        {ready && !initialValues && <EmptyData onBackClick={handleCancel} isNoData />}
        {ready && initialValues && (
          <Formik
            initialValues={initialValues}
            validationSchema={ScheduleSchema}
            validateOnBlur={true}
            onSubmit={(value, action) => {
              handleSubmit(value, action);
            }}
          >
            {({ values, errors, touched, handleBlur, isSubmitting, setFieldValue }) => {
              const daysOfWeekOptions = DAYS_OF_WEEK.map(day => ({ value: day, label: capitalize(day) }));
              const step = 15;
              return (
                <FormContainer>
                  <StyledTextField outlined name='id' label='ID' value={values.id} disabled />
                  <SelectField
                    isMulti={false}
                    defaultValue={daysOfWeekOptions.find(
                      dayOption => dayOption.value.toLocaleLowerCase() === values.day.toLocaleLowerCase()
                    )}
                    name='day'
                    options={daysOfWeekOptions}
                    onChange={(dayOption: { value: string; label: string }) => setFieldValue('day', dayOption.value)}
                    onBlur={handleBlur}
                    placeholder='Select Day of the week'
                    label='Day of the week'
                    changeOptionLabel='label'
                    changeOptionValue='value'
                    invalid={touched.day && errors.day}
                    helpText={touched.day && errors.day}
                  />
                  <BasicTimePicker
                    name='start'
                    label='Start Time'
                    defaultValue={values.start}
                    getTimeOptions={{ step }}
                    onChange={(time: { value: string; label: string }) => {
                      // set end time to start time plus "step"
                      setFieldValue('end', formatToDBTime(addMinutesToTime(time.value, step)));
                      return setFieldValue('start', formatToDBTime(time.value));
                    }}
                    onBlur={handleBlur}
                    invalid={touched.start && errors.start}
                    helpText={touched.start && errors.start}
                  />
                  <BasicTimePicker
                    name='end'
                    label='End time'
                    defaultValue={values.end}
                    getTimeOptions={{ step }}
                    onChange={(time: { value: string; label: string }) => setFieldValue('end', formatToDBTime(time.value))}
                    onBlur={handleBlur}
                    invalid={touched.end && errors.end}
                    helpText={touched.end && errors.end}
                  />
                  <ButtonContainer>
                    <Buttons>
                      <Button
                        unelevated
                        type='submit'
                        label='Save'
                        disabled={isSubmitting}
                        icon={isSubmitting ? <CircularProgress /> : null}
                      />
                      <Button theme='secondary' type='button' label='Cancel' onClick={handleCancel} />
                    </Buttons>
                    {scheduleId !== 'new' && <DeleteButton type='button' label='Delete' onClick={() => setDialog(true)} />}
                  </ButtonContainer>
                </FormContainer>
              );
            }}
          </Formik>
        )}
      </StyledCard>
      <SimpleDialog
        title='Delete Schedule'
        body='Are you sure you want to delete this schedule?'
        open={dialog}
        onClose={async e => {
          if (e.detail.action === 'accept') {
            await handleDelete();
          }
          setDialog(false);
        }}
        acceptLabel='Delete'
      />
    </Container>
  );
}

export default withRouter(ScheduleEdit);
