import React from 'react';
import { DataTable, DataTableContent, DataTableHeadCell, DataTableHeadCellProps } from '@rmwc/data-table';

import styled from '../styled-components';

export const FullWidthDataTable = styled(DataTable)`
  &&& {
    display: flex;
    width: 100%;
  }

  .rmwc-data-table__cell: first-of-type {
    padding-left: 16px;
  }
`;

export const FullWidthDataTableContent = styled(DataTableContent)`
  flex: 1;
  width: 100%;
`;

const StyledDataTableHeadCell = styled(DataTableHeadCell)`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
`;

export const InvertedDataTableHeadCell = (props: DataTableHeadCellProps) => {
  const { ...rest } = props;
  return (
    <th style={{ display: 'table-cell', padding: 0 }}>
      <StyledDataTableHeadCell tag='div' {...rest} />
    </th>
  );
};
