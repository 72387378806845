import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { format } from 'date-fns';

import { Button } from '@rmwc/button';
import { Card } from '@rmwc/card';
import { CircularProgress } from '@rmwc/circular-progress';
import { Typography } from '@rmwc/typography';
import { List, ListItem, ListItemPrimaryText, ListItemSecondaryText, ListItemText } from '@rmwc/list';
import { SimpleDialog } from '@rmwc/dialog';

import styled from '../../../styled-components';
import BreadCrumbs from '../../../components/Breadcrumbs';
import EmptyData from '../../../components/EmptyData';

import theme from '../../../constants/Theme';
import { formatTime } from '../../../constants/Helpers';
import { useError } from '../../../lib/error.hook';
import CmsSnackbarQueue from '../../../lib/CmsSnackbarQueue';

import { useDeleteServiceSchedule, useGetServiceSchedule } from '../../../lib/api/ServiceSchedule.hooks';

type ScheduleServiceViewProps = RouteComponentProps<{ id: string; ssid: string }>;

const Container = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  margin: 8px 0;
`;

const StyledCard = styled(Card)`
  display: flex;
  overflow: scroll;
`;

const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-start;

  & > * {
    margin: 5px;
  }
`;

const DetailsContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
`;

const StyledListItemPrimaryText = styled(ListItemPrimaryText)<{ color?: string; oneLine?: boolean }>`
  ${({ color }) => `
    color: ${color || 'black'};
  `}
`;

const StyledListItemSecondaryText = styled(ListItemSecondaryText)<{ color?: string }>`
  ${({ color }) => `
    color: ${color || 'black'};
  `}
`;

const DeleteButton = styled(Button)`
  &&& {
    color: ${theme.destructiveColor};
  }
`;

function ScheduleServiceView(props: ScheduleServiceViewProps) {
  const { history, match } = props;
  const serviceId = match.params.id;
  const serviceScheduleId = match.params.ssid;

  const getServiceSchedule = useGetServiceSchedule(serviceScheduleId);
  const { error: getError } = useError([getServiceSchedule.error]);

  const deleteServiceSchedule = useDeleteServiceSchedule(serviceScheduleId);
  useError([deleteServiceSchedule.error]);

  const [dialog, setDialog] = useState(false);

  useEffect(() => {
    getServiceSchedule.get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getServiceSchedule.get]);

  useEffect(() => {
    if (deleteServiceSchedule.data && !deleteServiceSchedule.error && !deleteServiceSchedule.isLoading) {
      CmsSnackbarQueue.notify({
        title: `Service Schedule ${deleteServiceSchedule.data.id} deleted successfully.`
      });
      history.push(`/cms/services/${serviceId}/schedules`);
    }
  }, [history, serviceId, deleteServiceSchedule.data, deleteServiceSchedule.error, deleteServiceSchedule.isLoading]);

  const handleDelete = async () => {
    await deleteServiceSchedule.serviceScheduleDelete();
  };

  const handleCancel = () => {
    history.goBack();
  };

  const viewSchedule = (scheduleId: number) => {
    history.push(`/cms/schedules/${scheduleId}`);
  };

  let ready = !getServiceSchedule.isLoading;
  let initialValues = getError ? null : getServiceSchedule.data;

  return (
    <Container>
      <BreadCrumbs
        crumbs={[
          { text: 'Services', path: `/cms/services` },
          { text: 'Schedules', path: `/cms/services/${serviceId}/schedules` },
          { text: 'View', path: '' }
        ]}
      />
      <TopBar>
        <Typography use='headline5'>Service Schedule</Typography>
      </TopBar>
      <StyledCard>
        {!ready && (
          <Spinner>
            <CircularProgress size='large' />
          </Spinner>
        )}
        {ready && !initialValues && <EmptyData onBackClick={handleCancel} isNoData />}
        {ready && initialValues && (
          <DetailsContainer>
            <List twoLine dense>
              <ListItem disabled>
                <ListItemText>
                  <StyledListItemPrimaryText>{initialValues.id}</StyledListItemPrimaryText>
                  <StyledListItemSecondaryText color={theme.primary}>ID</StyledListItemSecondaryText>
                </ListItemText>
              </ListItem>
              <ListItem disabled>
                <ListItemText>
                  <StyledListItemPrimaryText>{initialValues.service.id}</StyledListItemPrimaryText>
                  <StyledListItemSecondaryText color={theme.primary}>Service ID</StyledListItemSecondaryText>
                </ListItemText>
              </ListItem>
              <ListItem disabled>
                <ListItemText>
                  <StyledListItemPrimaryText>{initialValues.schedule.id}</StyledListItemPrimaryText>
                  <StyledListItemSecondaryText color={theme.primary}>Schedule ID</StyledListItemSecondaryText>
                </ListItemText>
              </ListItem>
              <ListItem disabled>
                <ListItemText>
                  <StyledListItemPrimaryText>{initialValues.service.name}</StyledListItemPrimaryText>
                  <StyledListItemSecondaryText color={theme.primary}>Service Name</StyledListItemSecondaryText>
                </ListItemText>
              </ListItem>
              <ListItem disabled>
                <ListItemText>
                  <StyledListItemPrimaryText>{initialValues.schedule.day}</StyledListItemPrimaryText>
                  <StyledListItemSecondaryText color={theme.primary}>Schedule Day</StyledListItemSecondaryText>
                </ListItemText>
              </ListItem>
              <ListItem disabled>
                <ListItemText>
                  <StyledListItemPrimaryText>
                    {formatTime(initialValues.schedule.start)} &#8212; {formatTime(initialValues.schedule.end)}
                  </StyledListItemPrimaryText>
                  <StyledListItemSecondaryText color={theme.primary}>Availability</StyledListItemSecondaryText>
                </ListItemText>
              </ListItem>
              <ListItem disabled>
                <ListItemText>
                  <StyledListItemPrimaryText>
                    {format(new Date(initialValues.created), 'MMM. dd, yyyy - h:mm:ss a')}
                  </StyledListItemPrimaryText>
                  <StyledListItemSecondaryText color={theme.primary}>Created</StyledListItemSecondaryText>
                </ListItemText>
              </ListItem>
            </List>
            <ButtonContainer>
              <Buttons>
                <Button theme='secondary' type='button' label='Go Back' onClick={handleCancel} />
              </Buttons>
              <Buttons>
                <Button
                  theme='secondary'
                  type='button'
                  label='View Schedule'
                  onClick={() => initialValues && viewSchedule(initialValues.schedule.id)}
                />
                <DeleteButton type='button' label='Delete' onClick={() => setDialog(true)} />
              </Buttons>
            </ButtonContainer>
          </DetailsContainer>
        )}
      </StyledCard>
      <SimpleDialog
        title='Delete Service Schedule'
        body='Are you sure you want to delete this Service Schedule?'
        open={dialog}
        onClose={async e => {
          if (e.detail.action === 'accept') {
            await handleDelete();
          }
          setDialog(false);
        }}
        acceptLabel='Delete'
      />
    </Container>
  );
}

export default withRouter(ScheduleServiceView);
