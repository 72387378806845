import React from 'react';
import { Redirect } from 'react-router';

import { Button } from '@rmwc/button';
import { Card } from '@rmwc/card';
import { CircularProgress } from '@rmwc/circular-progress';

import { Form, Formik, FormikHelpers as FormikActions } from 'formik';
import * as Yup from 'yup';

import TextField from '../../components/TextField';
import styled from '../../styled-components';

import { ErrorMessages } from '../../constants/Strings';
import { LogInDTO, useLogin } from '../../lib/api/User.hooks';

const LogInSchema = Yup.object().shape({
  email: Yup.string()
    .email(ErrorMessages.INVALID_EMAIL)
    .required(ErrorMessages.REQUIRED_EMAIL),
  password: Yup.string().required(ErrorMessages.REQUIRED_PASSWORD)
});

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
`;

const StyledCard = styled(Card)`
  width: 400px;
  display: flex;
`;

const FormContainer = styled(Form)`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 40px;
`;

const BottomContainer = styled.div`
  margin-top: 20px;
  align-self: stretch;
  flex: 1;
  display: flex;
  align-items: center;
`;

const ErrorContainer = styled.div`
  flex: 1;
  color: red;
`;

const StyledTextField = styled(TextField)`
  margin-top: 10px;
  margin-bottom: 10px;
  flex: 1;
  align-self: stretch;
`;

function LogIn() {
  const { data: user, error, doLogin } = useLogin();
  let loginError = error;

  const handleSubmit = async (values: LogInDTO, actions: FormikActions<LogInDTO>) => {
    actions.setSubmitting(true);
    await doLogin(values.email, values.password);
    actions.setSubmitting(false);
  };

  if (user) {
    if (user && user.roles && user.roles.find(r => r.name === 'Admin')) {
      return <Redirect to='/' />;
    } else {
      loginError = new Error('You do not have sufficient permissions.');
    }
  }

  return (
    <Container>
      <StyledCard>
        <Formik initialValues={{ email: '', password: '' }} validationSchema={LogInSchema} validateOnBlur={true} onSubmit={handleSubmit}>
          {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
            <FormContainer>
              <StyledTextField
                outlined
                type='email'
                name='email'
                label='Email'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                invalid={touched.email && errors.email}
                helpText={{ persistent: true, validationMsg: true, children: touched.email && errors.email }}
              />
              <StyledTextField
                outlined
                type='password'
                name='password'
                label='Password'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                invalid={touched.password && errors.password}
                helpText={{ persistent: true, validationMsg: true, children: touched.password && errors.password }}
              />
              <BottomContainer>
                <ErrorContainer>{loginError && loginError.message}</ErrorContainer>
                <Button unelevated type='submit' label='Log In' disabled={isSubmitting} icon={isSubmitting ? <CircularProgress /> : null} />
              </BottomContainer>
            </FormContainer>
          )}
        </Formik>
      </StyledCard>
    </Container>
  );
}

export default LogIn;
