export enum QuizTypeEnum {
  INITIAL = 1,
  QUARTERLY = 2
}

export type QuizTypeOption = { label: string; value: QuizTypeEnum };
export const QUIZ_TYPE_OPTIONS: QuizTypeOption[] = [
  { label: 'Initial', value: QuizTypeEnum.INITIAL },
  { label: 'Quarterly', value: QuizTypeEnum.QUARTERLY }
];
