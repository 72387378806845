import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';

import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';
import { DataTableBody, DataTableHead, DataTableRow, DataTableCell } from '@rmwc/data-table';
import { Typography } from '@rmwc/typography';

import styled from '../../../styled-components';
import Pagination from '../../../components/Pagination';
import Defaults from '../../../constants/Defaults';
import { FullWidthDataTable, FullWidthDataTableContent, InvertedDataTableHeadCell } from '../../../components/Table';
import { useGetQuestionsByPage } from '../../../lib/api/Question.hooks';
import parseSort from '../../../lib/parseSort';
import { QuestionTypeEnum, QuestionTypeOption, QUESTION_TYPE_OPTIONS } from '../../../enums/QuestionType.enum';
import BreadCrumbs from '../../../components/Breadcrumbs';
import { useGetQuiz } from '../../../lib/api/Quiz.hooks';
import EmptyData from '../../../components/EmptyData';
import { useError } from '../../../lib/error.hook';

type QuestionListProps = RouteComponentProps<{ id: string }>;

const Container = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  overflow: scroll;
`;

const FullWidthBar = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px 0;
  justify-content: space-between;
  align-items: center;
`;

const PointerDataTableRow = styled(DataTableRow)`
  cursor: pointer;
`;

const StyledPagination = styled(Pagination)`
  align-self: flex-end;
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
`;

function Spinner() {
  return (
    <SpinnerContainer>
      <CircularProgress size='large' />
    </SpinnerContainer>
  );
}

function QuestionList(props: QuestionListProps) {
  const { history, match } = props;
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [sort, setSort] = useQueryParam('sort', StringParam);
  const quizId = match.params.id;
  const getQuiz = useGetQuiz(quizId);

  const { data, count, isLoading, get, error: getQuestionsError } = useGetQuestionsByPage();
  const { error } = useError([getQuiz.error, getQuestionsError]);

  useEffect(() => {
    get({
      page: Number(page) || 0,
      sort: String(sort || ''),
      quizId: Number(quizId) || 0
    });
    getQuiz.get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get, page, sort, quizId]);

  useEffect(() => {
    setSort('weight ASC');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setNewSort = (column: string, direction: number | null) => {
    const newSort = direction ? `${column} ${direction > 0 ? 'DESC' : 'ASC'}` : undefined;
    setSort(newSort);
  };

  const editQuestion = (questionId: number) => {
    history.push(`/cms/quizzes/${quizId}/questions/${questionId}`);
  };

  const showChoices = (questionId: number) => {
    history.push(`/cms/quizzes/${quizId}/questions/${questionId}/choices`);
  };

  const setNewPage = (page: number) => {
    page = page - 1;
    setPage(page);
  };

  let sortObj;
  if (sort) {
    sortObj = parseSort(sort as string);
  }
  sortObj = sortObj || { column: '', direction: null };

  const getQuestionTypeLabel = (questionType: QuestionTypeEnum): string => {
    const questionTypeOption: QuestionTypeOption | undefined = QUESTION_TYPE_OPTIONS.find(type => type.value === questionType);
    return (questionTypeOption && questionTypeOption.label) || '';
  };

  return (
    <Container>
      <BreadCrumbs
        crumbs={[
          { text: 'Quizzes', path: `/cms/quizzes` },
          { text: 'Questions', path: `/cms/quizzes/${quizId}/questions`, disabled: true }
        ]}
      />
      <FullWidthBar>
        <Typography use='headline5'>Questions {getQuiz.data && getQuiz.data.name ? `- ${getQuiz.data.name}` : ''}</Typography>
        <Button unelevated label='Add New Question' icon='person_add' {...{ tag: Link, to: `/cms/quizzes/${quizId}/questions/new` }} />
      </FullWidthBar>
      <FullWidthDataTable>
        <FullWidthDataTableContent>
          <DataTableHead>
            <DataTableRow>
              <InvertedDataTableHeadCell
                sort={sortObj.column === 'id' ? sortObj.direction : null}
                onSortChange={dir => {
                  setNewSort('id', dir);
                }}
              >
                ID
              </InvertedDataTableHeadCell>
              <InvertedDataTableHeadCell
                sort={sortObj.column === 'question' ? sortObj.direction : null}
                onSortChange={dir => {
                  setNewSort('question', dir);
                }}
              >
                Question
              </InvertedDataTableHeadCell>
              <InvertedDataTableHeadCell
                sort={sortObj.column === 'type' ? sortObj.direction : null}
                onSortChange={dir => {
                  setNewSort('type', dir);
                }}
              >
                Type
              </InvertedDataTableHeadCell>
              <InvertedDataTableHeadCell
                sort={sortObj.column === 'weight' ? sortObj.direction : null}
                onSortChange={dir => {
                  setNewSort('weight', dir);
                }}
              >
                Weight
              </InvertedDataTableHeadCell>
              <InvertedDataTableHeadCell />
            </DataTableRow>
          </DataTableHead>
          <DataTableBody>
            {!isLoading &&
              !error &&
              data &&
              data.map(row => (
                <PointerDataTableRow key={row.id} onClick={() => editQuestion(row.id)}>
                  <DataTableCell>{row.id}</DataTableCell>
                  <DataTableCell style={{ maxWidth: 240, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                    {row.question}
                  </DataTableCell>
                  <DataTableCell>{getQuestionTypeLabel(row.type)}</DataTableCell>
                  <DataTableCell>#{row.weight}</DataTableCell>
                  <DataTableCell>
                    <Button
                      label='Show Choices'
                      onClick={event => {
                        event.stopPropagation();
                        showChoices(row.id);
                      }}
                    />
                  </DataTableCell>
                </PointerDataTableRow>
              ))}
          </DataTableBody>
        </FullWidthDataTableContent>
      </FullWidthDataTable>
      {isLoading && <Spinner />}
      {!isLoading && data && data.length === 0 && <EmptyData />}
      <FullWidthBar>
        <Typography use='subtitle1'>Total: {count}</Typography>
        <StyledPagination
          current={Number(page) + 1 || 1}
          total={Math.ceil(count / Defaults.LIST_PAGE_SIZE)}
          onPageChange={(page: number) => setNewPage(page)}
        />
      </FullWidthBar>
    </Container>
  );
}

export default withRouter(QuestionList);
