import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { Button } from '@rmwc/button';
import { Card } from '@rmwc/card';
import { CircularProgress } from '@rmwc/circular-progress';
import { Typography } from '@rmwc/typography';

import styled from '../../../styled-components';
import { useGetRanking } from '../../../lib/api/Ranking.hooks';
import BreadCrumbs from '../../../components/Breadcrumbs';
import { List, ListItem, ListItemPrimaryText, ListItemSecondaryText, ListItemText } from '@rmwc/list';
import theme from '../../../constants/Theme';
import EmptyData from '../../../components/EmptyData';
import { useError } from '../../../lib/error.hook';

type RankingEditProps = RouteComponentProps<{ id: string }>;

const Container = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  margin: 8px 0;
`;

const StyledCard = styled(Card)`
  display: flex;
  overflow: scroll;
`;

const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-start;

  & > * {
    margin: 5px;
  }
`;

const DetailsContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
`;

const StyledListItemPrimaryText = styled(ListItemPrimaryText)<{ color?: string; oneLine?: boolean }>`
  ${({ color }) => `
    color: ${color || 'black'};
  `}
`;

const StyledListItemSecondaryText = styled(ListItemSecondaryText)<{ color?: string }>`
  ${({ color }) => `
    color: ${color || 'black'};
  `}
`;

function RankingEdit(props: RankingEditProps) {
  const { history, match } = props;
  const rankingId = match.params.id;
  const getRanking = useGetRanking(rankingId);
  const { get } = getRanking;
  const { error: getError } = useError([getRanking.error]);

  useEffect(() => {
    if (rankingId !== 'new') {
      get();
    }
  }, [get, rankingId]);

  const handleCancel = () => {
    history.goBack();
  };

  let ready = !getRanking.isLoading;
  let initialValues = getError ? null : getRanking.data;

  return (
    <Container>
      <BreadCrumbs crumbs={[{ text: 'Rankings', path: '/cms/rankings' }, { text: 'View', path: '' }]} />
      <TopBar>
        <Typography use='headline5'>Ranking</Typography>
      </TopBar>
      <StyledCard>
        {!ready && (
          <Spinner>
            <CircularProgress size='large' />
          </Spinner>
        )}
        {ready && !initialValues && <EmptyData onBackClick={handleCancel} isNoData />}
        {ready && initialValues && (
          <DetailsContainer>
            <List twoLine dense>
              <ListItem disabled>
                <ListItemText>
                  <StyledListItemPrimaryText>{initialValues.id}</StyledListItemPrimaryText>
                  <StyledListItemSecondaryText color={theme.primary}>ID</StyledListItemSecondaryText>
                </ListItemText>
              </ListItem>
              <ListItem disabled>
                <ListItemText>
                  <StyledListItemPrimaryText>{initialValues.rank}</StyledListItemPrimaryText>
                  <StyledListItemSecondaryText color={theme.primary}>Rank</StyledListItemSecondaryText>
                </ListItemText>
              </ListItem>
              <ListItem disabled>
                <ListItemText>
                  <StyledListItemPrimaryText>{initialValues.from.toFixed(2)}</StyledListItemPrimaryText>
                  <StyledListItemSecondaryText color={theme.primary}>From</StyledListItemSecondaryText>
                </ListItemText>
              </ListItem>
              <ListItem disabled>
                <ListItemText>
                  <StyledListItemPrimaryText>{initialValues.to.toFixed(2)}</StyledListItemPrimaryText>
                  <StyledListItemSecondaryText color={theme.primary}>To</StyledListItemSecondaryText>
                </ListItemText>
              </ListItem>
            </List>
            <ButtonContainer>
              <Buttons>
                <Button theme='secondary' type='button' label='Go Back' onClick={handleCancel} />
              </Buttons>
            </ButtonContainer>
          </DetailsContainer>
        )}
      </StyledCard>
    </Container>
  );
}

export default withRouter(RankingEdit);
