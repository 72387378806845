import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import * as _ from 'lodash';

import { CircularProgress } from '@rmwc/circular-progress';
import { DataTableBody, DataTableHead, DataTableRow, DataTableCell } from '@rmwc/data-table';
import { Typography } from '@rmwc/typography';

import styled from '../../../styled-components';
import { FullWidthDataTable, FullWidthDataTableContent, InvertedDataTableHeadCell } from '../../../components/Table';
import { useGetAnswersByPage } from '../../../lib/api/Answer.hooks';
import BreadCrumbs from '../../../components/Breadcrumbs';
import { useGetSurvey, useGetSurveyRankingByScore } from '../../../lib/api/Survey.hooks';
import { Answer } from '../../../types/Answer';
import EmptyData from '../../../components/EmptyData';

type AnswerListProps = RouteComponentProps<{ id: string; sid: string }>;

const Container = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  overflow: scroll;
`;

const FullWidthBar = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px 0;
  justify-content: space-between;
  align-items: center;
`;

const StyledDataTableRow = styled(DataTableRow)`
  &:hover .rmwc-data-table__cell:before {
    background-color: white !important;
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
`;

const StyledDataTableCell = styled(DataTableCell)`
  background: none;
  border: 0;

  &.rmwc-data-table__cell {
    border-top: 0;
    border-bottom: 0;
  }

  &:first-of-type {
    padding-left: 16px;
  }
`;

function Spinner() {
  return (
    <SpinnerContainer>
      <CircularProgress size='large' />
    </SpinnerContainer>
  );
}

function AnswerList(props: AnswerListProps) {
  const { match } = props;
  const { id: subscriberId, sid: surveyId } = match.params;
  const getSurvey = useGetSurvey(surveyId);
  const getSurveyRanking = useGetSurveyRankingByScore(surveyId);
  const { data, count, isLoading, error, get } = useGetAnswersByPage();
  const [answersGroupedByQuestion, setAnswersGroupedByQuestion] = useState<_.Dictionary<Answer[]>>({});
  const [weightKeys, setWeightKeys] = useState<string[]>([]);

  useEffect(() => {
    get({
      surveyId: Number(surveyId) || 0
    });
  }, [get, surveyId]);

  useEffect(() => {
    getSurvey.get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSurvey.get]);

  useEffect(() => {
    getSurveyRanking.get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSurveyRanking.get]);

  useEffect(() => {
    setAnswersGroupedByQuestion(_.groupBy(data, answer => answer.question.id));
  }, [data]);

  useEffect(() => {
    setWeightKeys(Object.keys(answersGroupedByQuestion).sort((a, b) => parseInt(a) - parseInt(b)));
  }, [answersGroupedByQuestion]);

  return (
    <Container>
      <BreadCrumbs
        crumbs={[
          { text: 'Subscribers', path: '/cms/subscribers' },
          { text: 'Surveys', path: `/cms/subscribers/${subscriberId}/surveys` },
          { text: 'Answers', path: '' }
        ]}
      />
      <FullWidthBar>
        <Typography use='headline5'>Answers - {_.get(getSurvey.data, 'quiz.name') || ''}</Typography>
      </FullWidthBar>
      <FullWidthDataTable>
        <FullWidthDataTableContent>
          <DataTableHead>
            <DataTableRow>
              <InvertedDataTableHeadCell>Question</InvertedDataTableHeadCell>
              <InvertedDataTableHeadCell>Answer</InvertedDataTableHeadCell>
              <InvertedDataTableHeadCell>Score</InvertedDataTableHeadCell>
            </DataTableRow>
          </DataTableHead>
          <DataTableBody>
            {!isLoading &&
              !error &&
              weightKeys &&
              weightKeys.map(key =>
                answersGroupedByQuestion[key].map((row, index) => (
                  <StyledDataTableRow key={index}>
                    {index === 0 ? (
                      <>
                        <StyledDataTableCell
                          rowSpan={answersGroupedByQuestion[key].length}
                          dangerouslySetInnerHTML={{ __html: row.question.question }}
                        />
                        <StyledDataTableCell>{row.answer}</StyledDataTableCell>
                        <StyledDataTableCell>{row.score.toFixed(2)}</StyledDataTableCell>
                      </>
                    ) : (
                      <>
                        <StyledDataTableCell>{row.answer}</StyledDataTableCell>
                        <StyledDataTableCell>{row.score.toFixed(2)}</StyledDataTableCell>
                      </>
                    )}
                  </StyledDataTableRow>
                ))
              )}
            <StyledDataTableRow>
              <DataTableCell />
              <DataTableCell alignEnd>
                <b>Score</b>
              </DataTableCell>
              <DataTableCell>{((getSurvey.data && getSurvey.data.score) || 0).toFixed(2)}</DataTableCell>
            </StyledDataTableRow>
            <StyledDataTableRow>
              <DataTableCell />
              <DataTableCell alignEnd>
                <b>Ranking</b>
              </DataTableCell>
              <DataTableCell>{(getSurveyRanking.data && getSurveyRanking.data.rank) || <i>Unranked</i>}</DataTableCell>
            </StyledDataTableRow>
          </DataTableBody>
        </FullWidthDataTableContent>
      </FullWidthDataTable>
      {isLoading && <Spinner />}
      {!isLoading && !error && data && data.length === 0 && <EmptyData />}
      <FullWidthBar>
        <Typography use='subtitle1'>Total: {count}</Typography>
      </FullWidthBar>
    </Container>
  );
}

export default withRouter(AnswerList);
