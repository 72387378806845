import { useCallback } from 'react';

import { useApiGet } from './Api.hooks';
import { Answer } from '../../types/Answer';

export function useGetAnswer(answerId: string) {
  return useApiGet<Answer | null>(null, 'answers/' + answerId);
}

export function useGetAnswersByPage() {
  const { get: originalGet, data, ...rest } = useApiGet<[Answer[], number]>([[], 0], 'answers');

  const get = useCallback(
    async (params?: { page?: number; query?: string; sort?: string; limit?: number; surveyId?: number }) => {
      await originalGet({ ...params });
    },
    [originalGet]
  );

  const [responseData, count] = data;
  return { ...rest, data: responseData, count, get };
}
