import { Button } from '@rmwc/button';
import { Icon } from '@rmwc/icon';
import { Typography } from '@rmwc/typography';
import { Link } from 'react-router-dom';
import React from 'react';

import styled from '../styled-components';
import theme from '../constants/Theme';

const StyledBreadCrumbs = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 8px 0;
`;

type BreadCrumbsProps = {
  crumbs: { text: string; path: string; disabled?: boolean }[];
};

function BreadCrumbs(props: BreadCrumbsProps) {
  const { crumbs } = props;
  return (
    <StyledBreadCrumbs>
      <Icon icon='home' style={{ color: theme.grey, paddingLeft: 8, paddingRight: 8 }} />
      <Icon icon='chevron_right' style={{ color: theme.grey }} />
      {crumbs.map((crumb, index) => (
        <React.Fragment key={index}>
          {crumb.disabled || !crumb.path ? (
            <Typography use='button' tag='span' style={{ paddingLeft: 8, paddingRight: 8, color: theme.grey }}>
              {crumb.text}
            </Typography>
          ) : (
            <Button label={crumb.text} style={{ minWidth: 'unset' }} {...{ tag: Link, to: crumb.path }} />
          )}

          {crumbs.length - 1 > index && <Icon icon='chevron_right' style={{ color: theme.grey }} />}
        </React.Fragment>
      ))}
    </StyledBreadCrumbs>
  );
}

export default BreadCrumbs;
