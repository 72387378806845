import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import { format } from 'date-fns';

import { CircularProgress } from '@rmwc/circular-progress';
import { DataTableBody, DataTableHead, DataTableRow, DataTableCell } from '@rmwc/data-table';
import { Typography } from '@rmwc/typography';
import { Button } from '@rmwc/button';

import styled from '../../../styled-components';
import Pagination from '../../../components/Pagination';
import Defaults from '../../../constants/Defaults';
import { FullWidthDataTable, FullWidthDataTableContent, InvertedDataTableHeadCell } from '../../../components/Table';
import parseSort from '../../../lib/parseSort';
import { useGetSubscribersByPage } from '../../../lib/api/Subscriber.hooks';
import BreadCrumbs from '../../../components/Breadcrumbs';
import EmptyData from '../../../components/EmptyData';

type SubscriberListProps = RouteComponentProps;

const Container = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  overflow: scroll;
`;

const FullWidthBar = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px 0;
  justify-content: space-between;
  align-items: center;
`;

const PointerDataTableRow = styled(DataTableRow)`
  cursor: pointer;
`;

const StyledPagination = styled(Pagination)`
  align-self: flex-end;
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
`;

function Spinner() {
  return (
    <SpinnerContainer>
      <CircularProgress size='large' />
    </SpinnerContainer>
  );
}

function SubscriberList(props: SubscriberListProps) {
  const { history } = props;
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [sort = 'created DESC', setSort] = useQueryParam('sort', StringParam);

  const { data, count, isLoading, error, get } = useGetSubscribersByPage();

  useEffect(() => {
    get({
      page: Number(page) || 0,
      sort: String(sort || '')
    });
  }, [get, page, sort]);

  const setNewSort = (column: string, direction: number | null) => {
    const newSort = direction ? `${column} ${direction > 0 ? 'DESC' : 'ASC'}` : undefined;
    setSort(newSort);
  };

  const editSubscriber = (subscriberId: number) => {
    history.push(`/cms/subscribers/${subscriberId}`);
  };

  const showSurveys = (subscriberId: number) => {
    history.push(`/cms/subscribers/${subscriberId}/surveys`);
  };

  const setNewPage = (page: number) => {
    page = page - 1;
    setPage(page);
  };

  let sortObj;
  if (sort) {
    sortObj = parseSort(sort as string);
  }
  sortObj = sortObj || { column: '', direction: null };

  return (
    <Container>
      <BreadCrumbs crumbs={[{ text: 'Subscribers', path: '' }]} />
      <FullWidthBar>
        <Typography use='headline5'>Subscribers</Typography>
      </FullWidthBar>
      <FullWidthDataTable>
        <FullWidthDataTableContent>
          <DataTableHead>
            <DataTableRow>
              <InvertedDataTableHeadCell
                sort={sortObj.column === 'id' ? sortObj.direction : null}
                onSortChange={dir => {
                  setNewSort('id', dir);
                }}
              >
                ID
              </InvertedDataTableHeadCell>
              <InvertedDataTableHeadCell
                sort={sortObj.column === 'name' ? sortObj.direction : null}
                onSortChange={dir => {
                  setNewSort('name', dir);
                }}
              >
                Name
              </InvertedDataTableHeadCell>
              <InvertedDataTableHeadCell
                sort={sortObj.column === 'emailAddress' ? sortObj.direction : null}
                onSortChange={dir => {
                  setNewSort('emailAddress', dir);
                }}
              >
                Email Address
              </InvertedDataTableHeadCell>
              <InvertedDataTableHeadCell
                sort={sortObj.column === 'dentrixId' ? sortObj.direction : null}
                onSortChange={dir => {
                  setNewSort('dentrixId', dir);
                }}
              >
                Dentrix Id
              </InvertedDataTableHeadCell>
              <InvertedDataTableHeadCell
                sort={sortObj.column === 'shopifyId' ? sortObj.direction : null}
                onSortChange={dir => {
                  setNewSort('shopifyId', dir);
                }}
              >
                Shopify Id
              </InvertedDataTableHeadCell>
              <InvertedDataTableHeadCell
                sort={sortObj.column === 'ageGroup' ? sortObj.direction : null}
                onSortChange={dir => {
                  setNewSort('ageGroup', dir);
                }}
              >
                Age Group
              </InvertedDataTableHeadCell>
              <InvertedDataTableHeadCell
                sort={sortObj.column === 'created' ? sortObj.direction : null}
                onSortChange={dir => {
                  setNewSort('created', dir);
                }}
              >
                Created
              </InvertedDataTableHeadCell>
              <InvertedDataTableHeadCell />
            </DataTableRow>
          </DataTableHead>
          <DataTableBody>
            {!isLoading &&
              !error &&
              data &&
              data.map(row => (
                <PointerDataTableRow key={row.id} onClick={() => editSubscriber(row.id)}>
                  <DataTableCell>{row.id}</DataTableCell>
                  <DataTableCell>{row.name}</DataTableCell>
                  <DataTableCell>{row.emailAddress || '--'}</DataTableCell>
                  <DataTableCell>{row.dentrixId || '--'}</DataTableCell>
                  <DataTableCell>{row.shopifyId || '--'}</DataTableCell>
                  <DataTableCell>{row.ageGroup}</DataTableCell>
                  <DataTableCell>{format(new Date(row.created), 'MMM. dd, yyyy - h:mm:ss a')}</DataTableCell>
                  <DataTableCell>
                    <Button
                      label='Show Surveys'
                      onClick={event => {
                        event.stopPropagation();
                        showSurveys(row.id);
                      }}
                    />
                  </DataTableCell>
                </PointerDataTableRow>
              ))}
          </DataTableBody>
        </FullWidthDataTableContent>
      </FullWidthDataTable>
      {isLoading && <Spinner />}
      {!isLoading && !error && data && data.length === 0 && <EmptyData />}
      <FullWidthBar>
        <Typography use='subtitle1'>Total: {count}</Typography>
        <StyledPagination
          current={Number(page) + 1 || 1}
          total={Math.ceil(count / Defaults.LIST_PAGE_SIZE)}
          onPageChange={(page: number) => setNewPage(page)}
        />
      </FullWidthBar>
    </Container>
  );
}

export default withRouter(SubscriberList);
