import React from 'react';
import { TextField as RMWCTextField } from '@rmwc/textfield';

import styled from '../styled-components';

const FullWidthTextField = styled(RMWCTextField)`
  width: 100%;
`;

function TextField(props: any) {
  const { className, ...rest } = props;
  return (
    <div className={className}>
      <FullWidthTextField
        {...rest}
      />
    </div>
  );
}

export default TextField;
