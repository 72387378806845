import UserModule from './users/UserModule';
import QuizModule from './quizzes/QuizModule';
import SubscriberModule from './subscribers/SubscriberModule';
import RankingModule from './rankings/RankingModule';
import ScheduleModule from './schedules/ScheduleModule';
import BranchModule from './branches/BranchModule';
import ServiceModule from './services/ServiceModule';

type RouteDefintion = {
  icon?: JSX.Element | null;
  name: string;
  path: string;
  component: () => JSX.Element;
};

export const CMSRouteSections: RouteDefintion[] = [
  {
    icon: null,
    name: 'Quizzes',
    path: '/cms/quizzes',
    component: QuizModule
  },
  {
    icon: null,
    name: 'Subscribers',
    path: '/cms/subscribers',
    component: SubscriberModule
  },
  {
    icon: null,
    name: 'Rankings',
    path: '/cms/rankings',
    component: RankingModule
  },
  {
    icon: null,
    name: 'Branches',
    path: '/cms/branches',
    component: BranchModule
  },
  {
    icon: null,
    name: 'Services',
    path: '/cms/services',
    component: ServiceModule
  },
  {
    icon: null,
    name: 'Schedules',
    path: '/cms/schedules',
    component: ScheduleModule
  },
  {
    icon: null,
    name: 'Users',
    path: '/cms/users',
    component: UserModule
  }
];

export default CMSRouteSections;
