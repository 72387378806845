import { useCallback } from 'react';

import Defaults from '../../constants/Defaults';
import { useApiGet, useApiMutation } from './Api.hooks';
import { Quiz } from '../../types/Quiz';
import { HttpMethod } from './Api';
import { QuizTypeEnum } from '../../enums/QuizType.enum';

export function useGetQuiz(quizId: string) {
  return useApiGet<Quiz | null>(null, 'quizzes/' + quizId);
}

export function useGetQuizzesByPage() {
  const { get: originalGet, data, ...rest } = useApiGet<[Quiz[], number]>([[], 0], 'quizzes');

  const get = useCallback(
    async (params?: { page?: number; query?: string; sort?: string; limit?: number }) => {
      const defaults = {
        page: 0,
        limit: Defaults.LIST_PAGE_SIZE
      };
      await originalGet({ ...defaults, ...params });
    },
    [originalGet]
  );

  const [responseData, count] = data;

  return { ...rest, data: responseData, count, get };
}

export type QuizUpdateDTO = {
  name: string;
  type: QuizTypeEnum;
};

export function useUpdateQuiz(quizId: string) {
  const { mutate, ...rest } = useApiMutation<Quiz, QuizUpdateDTO>(null, HttpMethod.PATCH, 'quizzes/' + quizId);

  const update = useCallback(
    async (quiz: QuizUpdateDTO) => {
      await mutate(quiz);
    },
    [mutate]
  );

  return { ...rest, update };
}

export type QuizCreateDTO = {
  name: string;
  type: QuizTypeEnum;
};

export function useCreateQuiz() {
  const { mutate, ...rest } = useApiMutation<Quiz, QuizCreateDTO>(null, HttpMethod.POST, 'quizzes/');

  const create = useCallback(
    async (quiz: QuizCreateDTO) => {
      await mutate(quiz);
    },
    [mutate]
  );

  return { ...rest, create };
}

export function useDeleteQuiz(quizId: string) {
  const { mutate, ...rest } = useApiMutation<Quiz, null>(null, HttpMethod.DELETE, 'quizzes/' + quizId);

  const quizDelete = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return { ...rest, quizDelete };
}
