export enum ErrorMessages {
  INVALID_CREDENTIALS = 'The email and password do not match. Please check again.',
  NETWORK_ERROR = 'There is a network issue. Please try again later.',
  LOGOUT_ERROR = 'An error occured when trying to logout. Please try again.',
  ACCOUNT_UPDATE_ERROR = 'There was an error updating your account.',
  PERMISSION_REQUIRED = 'Permission required',
  PERMISSIONS_REQUIRED = 'Permissions required',
  INVALID_SESSION = 'Please login again.',

  // User
  INVALID_EMAIL = 'Invalid email',
  REQUIRED_EMAIL = 'Email is required',
  REQUIRED_PASSWORD = 'Password is required',
  INVALID_CHARACTER_COUNT_PASSWORD = 'Password should have atleast 8 characters or more.',
  INVALID_FORMAT_PASSWORD = 'Password should contain atleast one lowercase, one uppercase, and a number',
  REQUIRED_USERNAME = 'Username is required',
  REQUIRED_ROLES = 'Role is required',

  // Quiz Fields
  REQUIRED_NAME = 'Name is required',
  REQUIRED_TYPE = 'Type is required',

  // Survey Fields
  REQUIRED_SCORE = 'Score is required',
  POSITIVE_SCORE = 'Score should be a positive decimal number',
  POSITIVE_INTEGER_WEIGHT = 'Weight should be a positive integer',

  // Question Fields
  REQUIRED_QUESTION = 'Question is required',
  REQUIRED_WEIGHT = 'Weight is required',

  // Choice Fields
  REQUIRED_CHOICE = 'Choice is required',
  REQUIRED_WEIGHTING = 'Weighting is required',

  // Entities
  REQUIRED_QUIZ = 'Quiz is required',
  REQUIRED_SUBSCRIBER = 'Subscriber is required',

  // Schedule Fields
  REQUIRED_DAY = 'Day is required',
  INVALID_DAY = 'Day should be Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, or Sunday',
  REQUIRED_START = 'Start Time is required',
  REQUIRED_END = 'End Time is required',

  // Service Fields
  REQUIRED_SERVICE_CATEGORY = 'Procedure Category is required',
  REQUIRED_SERVICE_ID = 'Practice Procedure is required',
  REQUIRED_SERVICE_PRICE = 'Price is required',
  INVALID_SERVICE_PRICE = 'Price must be a number',
  MIN_SERVICE_PRICE_PREFIX = 'Price must be greater than or equal to ',
  MAX_SERVICE_PRICE_PREFIX = 'Price must be less than or equal to ',
  REQUIRED_SERVICE_BRANCH = 'Branch is required',

  // Service Schedule Copy Fields
  REQUIRED_SERVICES = 'At least one service is required',

  // Service Schedule Fields
  REQUIRED_SERVICE_SCHEDULE = 'Schedule is required',

  // Operatory Fields
  REQUIRED_OPERATORY_ID = 'Operatory ID is required',
  REQUIRED_PROVIDER_ID = 'Provider ID is required',

  // Branch Fields
  REQUIRED_BRANCH_ID = 'Branch ID is required',
  REQUIRED_BRANCH_NAME = 'Branch Name is required'
}

export enum AlertTitles {
  ACCOUNT_UPDATED = 'Account Updated',
  UNKOWN_ERROR = 'An error occured',
  INVALID_LOGIN = 'Invalid Login',
  CONFIRM_LOGOUT = 'Log Out',
  SUCCESS = 'Success',
  RESET_DETAILS_FAILED = 'Reset details failed',
  RESET_PASSWORD_FAILED = 'Reset password failed.',
  ACCOUNT_UPDATE_ERROR = 'Account Update Error',
  PERMISSIONS_ERROR = 'Permissions error'
}

export enum AlertMessages {
  LOGOUT = 'Are you sure you want to log out?'
}

export enum AlertButtons {
  LOGOUT_CONFIRM = 'Log Out'
}

export enum SuccessMessages {
  ACCOUNT_UPDATED_SUCCEEDED = 'Your account has been updated.'
}

export enum ResetPasswordStrings {
  RESET_PASSWORD_TITLE = 'Set your New Password',
  RESET_PASSWORD_DESCRIPTION = 'Enter details below to set a new password',
  RESET_PASSWORD_NEW_PASSWORD_LABEL = 'New Password',
  RESET_PASSWORD_CONFIRM_PASSWORD_LABEL = 'Confirm Password',
  RESET_PASSWORD_NEW_PASSWORD_ERROR_MESSAGE = 'New Password is required',
  RESET_PASSWORD_CONFIRM_PASSWORD_ERROR_MESSAGE = 'Confirm Password is required',
  RESET_PASSWORD_DOES_NOT_MATCH_ERROR_MESSAGE = "Password doesn't match"
}

export enum ButtonStrings {
  BUTTON_CONFIRM = 'Confirm'
}
