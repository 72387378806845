import React, { useEffect, useState } from 'react';
import { Route } from 'react-router';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { MenuItem, SimpleMenu } from '@rmwc/menu';
import {
  TopAppBar,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarNavigationIcon,
  TopAppBarTitle,
  TopAppBarActionItem,
  TopAppBarFixedAdjust
} from '@rmwc/top-app-bar';

import { useMedia } from '../lib/media/media.context';
import styled from '../styled-components';
import { useLogout } from '../lib/api/User.hooks';
import useUser from '../lib/user/useUser';

import NavigationDrawer from './cms/NavigationDrawer';
import CMSRouteSections from './cms/route-sections';

type CmsContainerProps = RouteComponentProps;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const StyledTopAppBar = styled(TopAppBar)`
  &&& {
    z-index: 10;
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  background-color: #eee;
`;

function CmsContainer(props: CmsContainerProps) {
  const { history } = props;

  const mediaBreakpoint = useMedia();
  const [open, setOpen] = useState(false);
  const { doLogout } = useLogout();
  const [user] = useUser();

  useEffect(() => {
    if (!user) {
      history.push('/login');
    }
  }, [history, user]);

  return (
    <Container>
      <StyledTopAppBar fixed={true}>
        <TopAppBarRow>
          <TopAppBarSection alignStart>
            {mediaBreakpoint.xs && <TopAppBarNavigationIcon icon='menu' onClick={() => setOpen(!open)} />}
            <TopAppBarTitle>CMS</TopAppBarTitle>
          </TopAppBarSection>
          <TopAppBarSection alignEnd>
            <SimpleMenu handle={<TopAppBarActionItem icon='account_box' />} anchorCorner='bottomLeft'>
              <MenuItem onClick={doLogout}>Log Out</MenuItem>
            </SimpleMenu>
          </TopAppBarSection>
        </TopAppBarRow>
      </StyledTopAppBar>
      <TopAppBarFixedAdjust />

      <Content>
        <NavigationDrawer
          modal={mediaBreakpoint.xs}
          open={!mediaBreakpoint.xs || open}
          onClose={() => setOpen(false)}
          sections={CMSRouteSections}
        />
        {CMSRouteSections.map(section => (
          <Route key={section.path} path={section.path} component={section.component} />
        ))}
      </Content>
    </Container>
  );
}

export default withRouter(CmsContainer);
