import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { Button } from '@rmwc/button';
import { Card } from '@rmwc/card';
import { CircularProgress } from '@rmwc/circular-progress';
import { SimpleDialog } from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';

import { Form, Formik, FormikHelpers as FormikActions } from 'formik';
import * as Yup from 'yup';

import { ErrorMessages } from '../../../constants/Strings';
import TextField from '../../../components/TextField';
import SelectField from '../../../components/SelectField';
import { useGetQuestion, useUpdateQuestion, useCreateQuestion, useDeleteQuestion } from '../../../lib/api/Question.hooks';
import CmsSnackbarQueue from '../../../lib/CmsSnackbarQueue';
import styled from '../../../styled-components';
import theme from '../../../constants/Theme';
import { QuestionTypeEnum, QuestionTypeOption, QUESTION_TYPE_OPTIONS } from '../../../enums/QuestionType.enum';
import { Quiz } from '../../../types/Quiz';
import { useGetQuiz } from '../../../lib/api/Quiz.hooks';
import { Question } from '../../../types/Question';
import BreadCrumbs from '../../../components/Breadcrumbs';
import EmptyData from '../../../components/EmptyData';
import { useError } from '../../../lib/error.hook';
import { handlePasteNumber, INVALID_INTEGER_CHARS } from '../../../constants/Helpers';

type QuestionEditProps = RouteComponentProps<{ id: string; qid: string }>;
type QuestionFormType = {
  id: number | string;
  question: string;
  type: QuestionTypeEnum;
  weight: number;
  quiz: Quiz | null;
};

const QuestionSchema = Yup.object().shape({
  question: Yup.string().required(ErrorMessages.REQUIRED_QUESTION),
  type: Yup.mixed<QuestionTypeEnum>().oneOf(Object.values(QuestionTypeEnum) as QuestionTypeEnum[]),
  weight: Yup.number()
    .required(ErrorMessages.REQUIRED_WEIGHT)
    .positive(ErrorMessages.POSITIVE_INTEGER_WEIGHT)
    .integer(ErrorMessages.POSITIVE_INTEGER_WEIGHT),
  quiz: Yup.object<Quiz>()
    .required(ErrorMessages.REQUIRED_QUIZ)
    .typeError(ErrorMessages.REQUIRED_QUIZ)
});

const Container = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  margin: 8px 0;
`;

const StyledCard = styled(Card)`
  display: flex;
`;

const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`;

const FormContainer = styled(Form)`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  overflow: scroll;

  & > * {
    flex-shrink: 0;
  }
`;

const StyledTextField = styled(TextField)`
  margin-top: 10px;
  margin-bottom: 10px;

  & .mdc-text-field--textarea textarea {
    resize: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-start;

  & > * {
    margin: 5px;
  }
`;

const DeleteButton = styled(Button)`
  &&& {
    color: ${theme.destructiveColor};
  }
`;

const SelectContainer = styled.div`
  margin-bottom: 10px;
`;

function QuestionEdit(props: QuestionEditProps) {
  const { history, match } = props;
  const { id: quizId, qid: questionId } = match.params;
  const getQuestion = useGetQuestion(questionId);
  const updateQuestion = useUpdateQuestion(questionId);
  const { get } = getQuestion;
  const getQuiz = useGetQuiz(quizId);
  const createQuestion = useCreateQuestion();
  const deleteQuestion = useDeleteQuestion(questionId);
  const [dialog, setDialog] = useState(false);
  const { error: getError } = useError([getQuiz.error, getQuestion.error]);

  useError([updateQuestion.error, createQuestion.error, deleteQuestion.error]);

  useEffect(() => {
    if (questionId !== 'new') {
      get();
    }
  }, [get, questionId]);

  useEffect(() => {
    getQuiz.get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getQuiz.get]);

  useEffect(() => {
    if (updateQuestion.data && !updateQuestion.error && !updateQuestion.isLoading) {
      CmsSnackbarQueue.notify({
        title: `Question saved successfully.`,
        actions: [
          {
            label: 'VIEW',
            onClick: () => history.push(`/cms/quizzes/${quizId}/questions/${updateQuestion.data!.id}`)
          }
        ]
      });

      // TODO this causes a warning. Fix warning.
      history.push(`/cms/quizzes/${quizId}/questions`);
    } else if (createQuestion.data && !createQuestion.error && !createQuestion.isLoading) {
      CmsSnackbarQueue.notify({
        title: `Question created successfully.`,
        actions: [
          {
            label: 'VIEW',
            onClick: () => history.push(`/cms/quizzes/${quizId}/questions/${createQuestion.data!.id}`)
          }
        ]
      });
      history.push(`/cms/quizzes/${quizId}/questions`);
    } else if (deleteQuestion.data && !deleteQuestion.error && !deleteQuestion.isLoading) {
      CmsSnackbarQueue.notify({
        title: `Question deleted successfully.`
      });
      history.push(`/cms/quizzes/${quizId}/questions`);
    }
  }, [
    quizId,
    history,
    updateQuestion.data,
    updateQuestion.error,
    updateQuestion.isLoading,
    createQuestion.data,
    createQuestion.error,
    createQuestion.isLoading,
    deleteQuestion.data,
    deleteQuestion.error,
    deleteQuestion.isLoading
  ]);

  const handleSubmit = async (
    values: QuestionFormType,
    actions: FormikActions<Question | { id: string; question: string; type: QuestionTypeEnum; weight: number; quiz: Quiz | null }>
  ) => {
    actions.setSubmitting(true);
    if (questionId === 'new') {
      let newQuestion = {
        question: values.question,
        type: values.type,
        weight: values.weight,
        quiz: values.quiz as Quiz
      };
      await createQuestion.create(newQuestion);
    } else {
      const questionObj = {
        question: values.question,
        type: values.type,
        weight: values.weight,
        quiz: values.quiz as Quiz
      };
      await updateQuestion.update(questionObj);
    }
    actions.setSubmitting(false);
  };

  const handleDelete = async () => {
    await deleteQuestion.questionDelete();
  };

  const handleCancel = () => {
    history.goBack();
  };

  let initialValues;
  let ready = false;

  if (questionId === 'new') {
    initialValues = {
      id: 'new',
      question: '',
      type: QuestionTypeEnum.SINGLE_CHOICE,
      weight: 1,
      quiz: getQuiz.data
    };
    ready = true;
  } else {
    initialValues = getError ? null : getQuestion.data;
    ready = !getQuestion.isLoading;
  }

  return (
    <Container>
      <BreadCrumbs
        crumbs={[
          { text: 'Quizzes', path: `/cms/quizzes` },
          { text: 'Questions', path: `/cms/quizzes/${quizId}/questions` },
          { text: `${questionId === 'new' ? 'Add' : 'Edit'}`, path: ``, disabled: true }
        ]}
      />
      <TopBar>
        <Typography use='headline5'>Question {getQuiz.data && getQuiz.data.name ? `- ${getQuiz.data.name}` : ''}</Typography>
      </TopBar>
      <StyledCard>
        {!ready && (
          <Spinner>
            <CircularProgress size='large' />
          </Spinner>
        )}
        {ready && !initialValues && <EmptyData onBackClick={handleCancel} isNoData />}
        {ready && initialValues && (
          <Formik
            initialValues={initialValues}
            validationSchema={QuestionSchema}
            validateOnBlur={true}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, errors, touched, handleChange, handleBlur, isSubmitting, setFieldValue }) => (
              <FormContainer>
                <StyledTextField outlined name='id' label='ID' value={values.id} disabled />
                <StyledTextField outlined name='quiz' label='Quiz Id' value={(values.quiz && values.quiz.id) || ''} disabled />
                <StyledTextField
                  outlined
                  textarea
                  type='text'
                  name='question'
                  label='Question'
                  rows={8}
                  style={{ width: '100%' }}
                  onChange={handleChange}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('question', values.question.trim());
                    handleBlur(e);
                  }}
                  value={values.question}
                  invalid={touched.question && errors.question}
                  helpText={{ persistent: true, validationMsg: true, children: touched.question && errors.question }}
                />
                <StyledTextField
                  outlined
                  type='number'
                  name='weight'
                  label='Weight'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onPaste={(e: ClipboardEvent) => {
                    handlePasteNumber(e, (res: number) => setFieldValue('weight', res));
                  }}
                  value={values.weight}
                  invalid={touched.weight && errors.weight}
                  onKeyDown={(e: KeyboardEvent) => INVALID_INTEGER_CHARS.includes(e.key) && e.preventDefault()}
                  helpText={{ persistent: true, validationMsg: true, children: touched.weight && errors.weight }}
                />
                <SelectContainer>
                  <SelectField
                    isMulti={false}
                    defaultValue={QUESTION_TYPE_OPTIONS.find(v => v.value === values.type)}
                    name='type'
                    options={QUESTION_TYPE_OPTIONS}
                    onChange={(questionType: QuestionTypeOption) => setFieldValue('type', questionType.value)}
                    onBlur={handleBlur}
                    placeholder='Select Question Type'
                    label='Question Type'
                    changeOptionLabel='label'
                    changeOptionValue='value'
                    invalid={touched.type && errors.type}
                    helpText={touched.type && errors.type}
                  />
                </SelectContainer>
                <ButtonContainer>
                  <Buttons>
                    <Button
                      unelevated
                      type='submit'
                      label='Save'
                      disabled={isSubmitting}
                      icon={isSubmitting ? <CircularProgress /> : null}
                    />
                    <Button theme='secondary' type='button' label='Cancel' onClick={handleCancel} />
                  </Buttons>
                  {questionId !== 'new' && <DeleteButton type='button' label='Delete' onClick={() => setDialog(true)} />}
                </ButtonContainer>
              </FormContainer>
            )}
          </Formik>
        )}
      </StyledCard>
      <SimpleDialog
        title='Delete Question'
        body='Are you sure you want to delete this question?'
        open={dialog}
        onClose={async e => {
          if (e.detail.action === 'accept') {
            await handleDelete();
          }
          setDialog(false);
        }}
        acceptLabel='Delete'
      />
    </Container>
  );
}

export default withRouter(QuestionEdit);
