import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { Button } from '@rmwc/button';
import { Card } from '@rmwc/card';
import { CircularProgress } from '@rmwc/circular-progress';
import { Typography } from '@rmwc/typography';
import { SimpleDialog } from '@rmwc/dialog';

import { Form, Formik, FormikHelpers as FormikActions } from 'formik';
import * as Yup from 'yup';

import styled from '../../../styled-components';
import TextField from '../../../components/TextField';
import BreadCrumbs from '../../../components/Breadcrumbs';
import SelectField from '../../../components/SelectField';

import theme from '../../../constants/Theme';
import { ErrorMessages } from '../../../constants/Strings';
import { useError } from '../../../lib/error.hook';
import CmsSnackbarQueue from '../../../lib/CmsSnackbarQueue';

import {
  useGetService,
  useDeleteService,
  useCreateService,
  useUpdateService,
  useGetPracticeProcedures,
  useGetProcedureCategories,
  useGetPracticeProcedure
} from '../../../lib/api/Service.hooks';
import { useGetBranchesByPage } from '../../../lib/api/Branch.hooks';
import { PracticeProcedure } from '../../../types/Service';

type ServiceEditProps = RouteComponentProps<{ id: string }>;
type ServiceFormType = {
  category: string;
  id: string;
  name: string;
  price: string;
  branchId: string;
};

const MIN_PRICE = 0.01;
const MAX_PRICE = 9999.99;
const ServiceSchema = Yup.object().shape({
  category: Yup.string().required(ErrorMessages.REQUIRED_SERVICE_CATEGORY),
  id: Yup.string().required(ErrorMessages.REQUIRED_SERVICE_ID),
  price: Yup.number()
    .min(MIN_PRICE, ErrorMessages.MIN_SERVICE_PRICE_PREFIX + MIN_PRICE)
    .max(MAX_PRICE, ErrorMessages.MAX_SERVICE_PRICE_PREFIX + MAX_PRICE)
    .typeError(ErrorMessages.INVALID_SERVICE_PRICE)
    .required(ErrorMessages.REQUIRED_SERVICE_PRICE),
  branchId: Yup.string().required(ErrorMessages.REQUIRED_SERVICE_BRANCH)
});

const Container = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  margin: 8px 0;
`;

const StyledCard = styled(Card)`
  display: flex;
`;

const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`;

const FormContainer = styled(Form)`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  overflow: scroll;

  & > * {
    flex-shrink: 0;
  }
`;

const StyledTextField = styled(TextField)`
  margin-top: 10px;
  margin-bottom: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-start;

  & > * {
    margin: 5px;
  }
`;

const DeleteButton = styled(Button)`
  &&& {
    color: ${theme.destructiveColor};
  }
`;

const SERVICES_ROUTE = '/cms/services/';
const NEW_SERVICE_ID = 'new';

const ServiceEdit = (props: ServiceEditProps) => {
  const { history, match } = props;
  const serviceId = match.params.id;
  const isNewService = serviceId === NEW_SERVICE_ID;

  const getService = useGetService(serviceId);
  const getBranches = useGetBranchesByPage();
  const getProcedureCategories = useGetProcedureCategories();
  const getPracticeProcedures = useGetPracticeProcedures();
  const getPracticeProcedure = useGetPracticeProcedure(serviceId.toString());
  const { error: getError } = useError([
    getService.error,
    getBranches.error,
    getProcedureCategories.error,
    getPracticeProcedures.error,
    getPracticeProcedure.error
  ]);

  const createService = useCreateService();
  const updateService = useUpdateService(serviceId);
  const deleteService = useDeleteService(serviceId);
  useError([createService.error, updateService.error, deleteService.error]);

  const [dialog, setDialog] = useState(false);
  const [isInitialProceduresLoaded, setIsInitialProceduresLoaded] = useState(false);

  const procedureRef = useRef<any>(null);

  useEffect(() => {
    if (!isNewService) {
      getService.get();
      getPracticeProcedure.get();
    }
    getProcedureCategories.get();
    getBranches.get({
      page: 0,
      limit: 1000
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewService, getService.get, getProcedureCategories.get, getBranches.get]);

  useEffect(() => {
    if (getPracticeProcedure.data && !getPracticeProcedure.error && !getPracticeProcedure.isLoading) {
      const { category } = getPracticeProcedure.data;
      getPracticeProcedures.get({ category });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPracticeProcedure.data, getPracticeProcedure.error, getPracticeProcedure.isLoading, getPracticeProcedures.get]);

  useEffect(() => {
    if (getError || (getPracticeProcedures.data.length && !getPracticeProcedures.error && !getPracticeProcedures.isLoading)) {
      setIsInitialProceduresLoaded(true);
    }
  }, [getError, setIsInitialProceduresLoaded, getPracticeProcedures.data, getPracticeProcedures.error, getPracticeProcedures.isLoading]);

  useEffect(() => {
    if (createService.data && !createService.error && !createService.isLoading) {
      const { id, name } = createService.data;
      CmsSnackbarQueue.notify({
        title: `Service ${id} (${name}) created successfully.`,
        actions: [
          {
            label: 'VIEW',
            onClick: () => history.push(SERVICES_ROUTE + id)
          }
        ]
      });
      history.push('/cms/services');
    } else if (updateService.data && !updateService.error && !updateService.isLoading) {
      const { id, name } = updateService.data;
      CmsSnackbarQueue.notify({
        title: `Service ${id} (${name}) saved successfully.`,
        actions: [
          {
            label: 'VIEW',
            onClick: () => history.push(SERVICES_ROUTE + id)
          }
        ]
      });
      history.push('/cms/services');
    } else if (deleteService.data && !deleteService.error && !deleteService.isLoading) {
      const { id, name } = deleteService.data;
      CmsSnackbarQueue.notify({
        title: `Service ${id} (${name}) deleted successfully.`
      });
      history.push(SERVICES_ROUTE);
    }
  }, [
    history,
    createService.data,
    createService.error,
    createService.isLoading,
    updateService.data,
    updateService.error,
    updateService.isLoading,
    deleteService.data,
    deleteService.error,
    deleteService.isLoading
  ]);

  const handleSubmit = async (values: ServiceFormType, actions: FormikActions<ServiceFormType>) => {
    actions.setSubmitting(true);

    if (isNewService) {
      await createService.create(values);
    } else {
      await updateService.update(values);
    }
    actions.setSubmitting(false);
  };

  const handleDelete = async () => {
    await deleteService.serviceDelete();
  };

  const handleCancel = () => {
    history.goBack();
  };

  let initialValues;
  let ready = false;

  if (isNewService) {
    initialValues = { category: '', id: '', name: '', price: '', branchId: '' };
    ready = !getProcedureCategories.isLoading && !getBranches.isLoading;
  } else {
    const { category: procedureCategory = '' } = getPracticeProcedure.data || {};
    const data = getService.data;
    initialValues = getError ? null : { category: procedureCategory, ...data, branchId: data && data.branch.id };
    ready =
      !getService.isLoading &&
      !getProcedureCategories.isLoading &&
      !getPracticeProcedure.isLoading &&
      !getBranches.isLoading &&
      isInitialProceduresLoaded;
  }

  return (
    <Container>
      <BreadCrumbs crumbs={[{ text: 'Services', path: '/cms/services' }, { text: `${isNewService ? 'Add' : 'Edit'}`, path: '' }]} />
      <TopBar>
        <Typography use='headline5'>Service</Typography>
      </TopBar>
      <StyledCard>
        {!ready && (
          <Spinner>
            <CircularProgress size='large' />
          </Spinner>
        )}
        {ready && initialValues && (
          <Formik
            initialValues={initialValues as ServiceFormType}
            validationSchema={ServiceSchema}
            validateOnBlur={true}
            onSubmit={(value, action) => {
              handleSubmit(value, action);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, isSubmitting, setFieldValue }) => {
              const isProcedureDisabled = !values.category || getPracticeProcedures.isLoading;
              const categoryOptions = getProcedureCategories.data.map(({ name, description }) => ({
                value: name,
                label: description
              }));
              const procedureOptions = getPracticeProcedures.data.map(({ id, description }) => ({
                value: id,
                label: `${id} - ${description}`
              }));
              const branchOptions = getBranches.data.map(branch => ({ value: branch.id, label: branch.name }));

              return (
                <FormContainer>
                  <SelectField
                    isSearchable
                    defaultValue={categoryOptions.find(categoryOption => categoryOption.value === values.category)}
                    name='category'
                    options={categoryOptions}
                    onChange={({ value }: { value: string }) =>
                      setTimeout(() => {
                        setFieldValue('category', value, true);
                        getPracticeProcedures.get({ category: value });
                        if (values.id && procedureRef.current) {
                          procedureRef.current.select.clearValue();
                        }
                      })
                    }
                    onBlur={handleBlur}
                    placeholder='Filter by Category'
                    label='Dentrix Procedure Category'
                    changeOptionLabel='label'
                    changeOptionValue='value'
                    invalid={touched.category && errors.category}
                    helpText={touched.category && errors.category}
                  />
                  <SelectField
                    selectRef={procedureRef}
                    isSearchable
                    defaultValue={procedureOptions.find(procedureOption => procedureOption.value === values.id)}
                    name='id'
                    options={procedureOptions}
                    onChange={(procedureOption: { value: string }) => {
                      if (!procedureOption) {
                        setFieldValue('id', '', true);
                        setFieldValue('name', '', true);
                        return;
                      }

                      const { value } = procedureOption;
                      setTimeout(() => {
                        const { description } =
                          getPracticeProcedures.data.find(procedure => procedure.id === value) || ({} as PracticeProcedure);
                        setFieldValue('id', value, true);
                        setFieldValue('name', description, true);
                      });
                    }}
                    onBlur={handleBlur}
                    placeholder='Select a Procedure'
                    label='Dentrix Practice Procedure'
                    changeOptionLabel='label'
                    changeOptionValue='value'
                    invalid={isProcedureDisabled ? null : touched.id && errors.id}
                    helpText={isProcedureDisabled ? null : touched.id && errors.id}
                    isLoading={getPracticeProcedures.isLoading}
                    isDisabled={isProcedureDisabled}
                  />
                  <StyledTextField outlined type='text' name='id' label='Service ID' value={values.id} disabled />
                  <StyledTextField outlined type='text' name='name' label='Service Name' value={values.name} disabled />
                  <StyledTextField
                    outlined
                    type='text'
                    name='price'
                    label='Price'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.price}
                    invalid={touched.price && errors.price}
                    helpText={{ persistent: true, validationMsg: true, children: touched.price && errors.price }}
                  />
                  <SelectField
                    isMulti={false}
                    defaultValue={branchOptions.find(branchOption => branchOption.value === values.branchId)}
                    name='branchId'
                    options={branchOptions}
                    onChange={(branchOption: { value: string; label: string }) =>
                      setTimeout(() => setFieldValue('branchId', branchOption.value, true))
                    }
                    onBlur={handleBlur}
                    placeholder='Select a Branch'
                    label='Branch'
                    changeOptionLabel='label'
                    changeOptionValue='value'
                    invalid={touched.branchId && errors.branchId}
                    helpText={touched.branchId && errors.branchId}
                  />
                  <ButtonContainer>
                    <Buttons>
                      <Button
                        unelevated
                        type='submit'
                        label='Save'
                        disabled={isSubmitting}
                        icon={isSubmitting ? <CircularProgress /> : null}
                      />
                      <Button theme='secondary' type='button' label='Cancel' onClick={handleCancel} />
                    </Buttons>
                    {!isNewService && <DeleteButton type='button' label='Delete' onClick={() => setDialog(true)} />}
                  </ButtonContainer>
                </FormContainer>
              );
            }}
          </Formik>
        )}
      </StyledCard>
      <SimpleDialog
        title='Delete Service'
        body='Are you sure you want to delete this Service?'
        open={dialog}
        onClose={async e => {
          if (e.detail.action === 'accept') {
            await handleDelete();
          }
          setDialog(false);
        }}
        acceptLabel='Delete'
      />
    </Container>
  );
};

export default withRouter(ServiceEdit);
