import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';

import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';
import { DataTableBody, DataTableHead, DataTableRow, DataTableCell } from '@rmwc/data-table';
import { Typography } from '@rmwc/typography';

import styled from '../../../styled-components';
import Pagination from '../../../components/Pagination';
import Defaults from '../../../constants/Defaults';
import { FullWidthDataTable, FullWidthDataTableContent, InvertedDataTableHeadCell } from '../../../components/Table';
import { useGetChoicesByPage } from '../../../lib/api/Choice.hooks';
import parseSort from '../../../lib/parseSort';
import BreadCrumbs from '../../../components/Breadcrumbs';
import { useGetQuestion } from '../../../lib/api/Question.hooks';
import EmptyData from '../../../components/EmptyData';
import { useError } from '../../../lib/error.hook';

type ChoiceListProps = RouteComponentProps<{ id: string; qid: string; cid: string }>;

const Container = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  overflow: scroll;
`;

const FullWidthBar = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px 0;
  justify-content: space-between;
  align-items: center;
`;

const PointerDataTableRow = styled(DataTableRow)`
  cursor: pointer;
`;

const StyledPagination = styled(Pagination)`
  align-self: flex-end;
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
`;

function Spinner() {
  return (
    <SpinnerContainer>
      <CircularProgress size='large' />
    </SpinnerContainer>
  );
}

function ChoiceList(props: ChoiceListProps) {
  const { history, match } = props;
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [sort, setSort] = useQueryParam('sort', StringParam);
  const { id: quizId, qid: questionId } = match.params;
  const getQuestion = useGetQuestion(questionId);

  const { data, count, isLoading, get, error: getChoicesError } = useGetChoicesByPage();
  const { error } = useError([getQuestion.error, getChoicesError]);

  useEffect(() => {
    get({
      page: Number(page) || 0,
      sort: String(sort || ''),
      questionId: Number(questionId) || 0
    });
    getQuestion.get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get, page, sort, questionId]);

  useEffect(() => {
    setSort('weight ASC');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setNewSort = (column: string, direction: number | null) => {
    const newSort = direction ? `${column} ${direction > 0 ? 'DESC' : 'ASC'}` : undefined;
    setSort(newSort);
  };

  const editChoice = (choiceId: number) => {
    history.push(`/cms/quizzes/${quizId}/questions/${questionId}/choices/${choiceId}`);
  };

  const setNewPage = (page: number) => {
    page = page - 1;
    setPage(page);
  };

  let sortObj;
  if (sort) {
    sortObj = parseSort(sort as string);
  }
  sortObj = sortObj || { column: '', direction: null };

  return (
    <Container>
      <BreadCrumbs
        crumbs={[
          { text: 'Quizzes', path: `/cms/quizzes` },
          { text: 'Questions', path: `/cms/quizzes/${quizId}/questions` },
          { text: 'Choices', path: `/cms/quizzes/${quizId}/questions/${questionId}/choices`, disabled: true }
        ]}
      />
      <FullWidthBar>
        <Typography use='headline5' style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: '80%' }}>
          Choices {getQuestion.data && getQuestion.data.question ? `- ${getQuestion.data.question}` : ''}
        </Typography>
        <Button
          unelevated
          label='Add New Choice'
          icon='person_add'
          {...{ tag: Link, to: `/cms/quizzes/${quizId}/questions/${questionId}/choices/new` }}
        />
      </FullWidthBar>
      <FullWidthDataTable>
        <FullWidthDataTableContent>
          <DataTableHead>
            <DataTableRow>
              <InvertedDataTableHeadCell
                sort={sortObj.column === 'id' ? sortObj.direction : null}
                onSortChange={dir => {
                  setNewSort('id', dir);
                }}
              >
                ID
              </InvertedDataTableHeadCell>
              <InvertedDataTableHeadCell
                sort={sortObj.column === 'choice' ? sortObj.direction : null}
                onSortChange={dir => {
                  setNewSort('choice', dir);
                }}
              >
                Choice
              </InvertedDataTableHeadCell>
              <InvertedDataTableHeadCell
                sort={sortObj.column === 'weighting' ? sortObj.direction : null}
                onSortChange={dir => {
                  setNewSort('weighting', dir);
                }}
              >
                Weighting
              </InvertedDataTableHeadCell>
              <InvertedDataTableHeadCell
                sort={sortObj.column === 'score' ? sortObj.direction : null}
                onSortChange={dir => {
                  setNewSort('score', dir);
                }}
              >
                Score
              </InvertedDataTableHeadCell>
              <InvertedDataTableHeadCell
                sort={sortObj.column === 'freetext' ? sortObj.direction : null}
                onSortChange={dir => {
                  setNewSort('freetext', dir);
                }}
              >
                Freetext?
              </InvertedDataTableHeadCell>
              <InvertedDataTableHeadCell
                sort={sortObj.column === 'validateText' ? sortObj.direction : null}
                onSortChange={dir => {
                  setNewSort('validateText', dir);
                }}
              >
                Validate Text?
              </InvertedDataTableHeadCell>
              <InvertedDataTableHeadCell
                sort={sortObj.column === 'weight' ? sortObj.direction : null}
                onSortChange={dir => {
                  setNewSort('weight', dir);
                }}
              >
                Weight
              </InvertedDataTableHeadCell>
            </DataTableRow>
          </DataTableHead>
          <DataTableBody>
            {!isLoading &&
              !error &&
              data &&
              data.map(row => (
                <PointerDataTableRow key={row.id} onClick={() => editChoice(row.id)}>
                  <DataTableCell>{row.id}</DataTableCell>
                  <DataTableCell>{row.choice}</DataTableCell>
                  <DataTableCell>{(row.weighting * 100).toFixed(1)}%</DataTableCell>
                  <DataTableCell>{row.score.toFixed(2)}</DataTableCell>
                  <DataTableCell>{row.freetext ? 'Yes' : 'No'}</DataTableCell>
                  <DataTableCell>{row.validateText ? 'Yes' : 'No'}</DataTableCell>
                  <DataTableCell>#{row.weight}</DataTableCell>
                </PointerDataTableRow>
              ))}
          </DataTableBody>
        </FullWidthDataTableContent>
      </FullWidthDataTable>
      {isLoading && <Spinner />}
      {!isLoading && data && data.length === 0 && <EmptyData />}
      <FullWidthBar>
        <Typography use='subtitle1'>Total: {count}</Typography>
        <StyledPagination
          current={Number(page) + 1 || 1}
          total={Math.ceil(count / Defaults.LIST_PAGE_SIZE)}
          onPageChange={(page: number) => setNewPage(page)}
        />
      </FullWidthBar>
    </Container>
  );
}

export default withRouter(ChoiceList);
