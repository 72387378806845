import React from 'react';

import { Link, withRouter, RouteComponentProps } from 'react-router-dom';

import { Drawer, DrawerContent, DrawerProps } from '@rmwc/drawer';
import { List, ListItem } from '@rmwc/list';

import styled from '../../styled-components';

type NavigationDrawerProps = RouteComponentProps &
  DrawerProps & {
    sections: any[];
  };

// https://www.styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
const StyledDrawer = styled(Drawer)`
  &&& {
    width: 200px;
    height: 100%;
  }
`;

function NavigationDrawer(props: NavigationDrawerProps) {
  const { sections, location, staticContext, ...others } = props;

  return (
    <StyledDrawer {...others}>
      <DrawerContent>
        <List>
          {sections.map(section => (
            <ListItem
              selected={location.pathname.indexOf(section.path) === 0}
              key={section.path}
              // https://rmwc.io/library-integrations
              {...{ tag: Link, to: section.path }}
            >
              {section.name}
            </ListItem>
          ))}
        </List>
      </DrawerContent>
    </StyledDrawer>
  );
}

export default withRouter(NavigationDrawer);
