import { useCallback } from 'react';

import Defaults from '../../constants/Defaults';
import { useApiGet, useApiMutation } from './Api.hooks';
import { Question } from '../../types/Question';
import { HttpMethod } from './Api';
import { Quiz } from '../../types/Quiz';
import { QuestionTypeEnum } from '../../enums/QuestionType.enum';

export function useGetQuestion(questionId: string) {
  return useApiGet<Question | null>(null, 'questions/' + questionId);
}

export function useGetQuestionsByPage() {
  const { get: originalGet, data, ...rest } = useApiGet<[Question[], number]>([[], 0], 'questions');

  const get = useCallback(
    async (params?: { page?: number; query?: string; sort?: string; limit?: number; quizId?: number }) => {
      const defaults = {
        page: 0,
        limit: Defaults.LIST_PAGE_SIZE
      };
      await originalGet({ ...defaults, ...params });
    },
    [originalGet]
  );

  const [responseData, count] = data;
  return { ...rest, data: responseData, count, get };
}

export type QuestionUpdateDTO = {
  question: string;
  type: QuestionTypeEnum;
  weight: number;
  quiz: Quiz;
};

export function useUpdateQuestion(questionId: string) {
  const { mutate, ...rest } = useApiMutation<Question, QuestionUpdateDTO>(null, HttpMethod.PATCH, 'questions/' + questionId);

  const update = useCallback(
    async (question: QuestionUpdateDTO) => {
      await mutate(question);
    },
    [mutate]
  );

  return { ...rest, update };
}

export type QuestionCreateDTO = {
  question: string;
  type: QuestionTypeEnum;
  weight: number;
  quiz: Quiz;
};

export function useCreateQuestion() {
  const { mutate, ...rest } = useApiMutation<Question, QuestionCreateDTO>(null, HttpMethod.POST, 'questions/');

  const create = useCallback(
    async (question: QuestionCreateDTO) => {
      await mutate(question);
    },
    [mutate]
  );

  return { ...rest, create };
}

export function useDeleteQuestion(questionId: string) {
  const { mutate, ...rest } = useApiMutation<Question, null>(null, HttpMethod.DELETE, 'questions/' + questionId);

  const questionDelete = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return { ...rest, questionDelete };
}
