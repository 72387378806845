import { useCallback } from 'react';

import Defaults from '../../constants/Defaults';
import { useApiGet, useApiMutation } from './Api.hooks';
import { HttpMethod } from './Api';
import { Branch, Location } from '../../types/Branch';

export function useGetBranch(branchId: string) {
  return useApiGet<Branch | null>(null, `branches/${branchId}`);
}

export function useGetLocations() {
  return useApiGet<Location[]>([], 'branches/locations');
}

export function useGetBranchesByPage() {
  const { get: originalGet, data, ...rest } = useApiGet<[Branch[], number]>([[], 0], 'branches');

  const get = useCallback(
    async (params?: { page?: number; query?: string; sort?: string; limit?: number }) => {
      const defaults = {
        page: 0,
        limit: Defaults.LIST_PAGE_SIZE
      };
      await originalGet({ ...defaults, ...params });
    },
    [originalGet]
  );

  const [responseData, count] = data;
  return { ...rest, data: responseData, count, get };
}

export function useDeleteBranch(branchId: string) {
  const { mutate, ...rest } = useApiMutation<Branch, null>(null, HttpMethod.DELETE, 'branches/' + branchId);

  const branchDelete = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return { ...rest, branchDelete };
}

export type BranchCreateDTO = {
  id: string;
  name: string;
  selectOperatory?: boolean;
};

export function useCreateBranch() {
  const { mutate, isLoading, ...rest } = useApiMutation<Branch, BranchCreateDTO>(null, HttpMethod.POST, 'branches/new');

  const branchCreate = useCallback(
    async (branch: BranchCreateDTO) => {
      await mutate(branch);
    },
    [mutate]
  );

  return { ...rest, branchCreate, isLoading };
}

export type BranchUpdateDTO = {
  selectOperatory?: boolean;
};

export function useUpdateBranch(branchId: string) {
  const { mutate, ...rest } = useApiMutation<Branch, BranchUpdateDTO>(null, HttpMethod.PATCH, 'branches/' + branchId);

  const update = useCallback(
    async (branch: BranchUpdateDTO) => {
      await mutate(branch);
    },
    [mutate]
  );

  return { ...rest, update };
}
