export enum QuestionTypeEnum {
  SINGLE_CHOICE = 1,
  MULTIPLE_CHOICE = 2
}

export type QuestionTypeOption = { label: string; value: QuestionTypeEnum };
export const QUESTION_TYPE_OPTIONS: QuestionTypeOption[] = [
  { label: 'Single Choice', value: QuestionTypeEnum.SINGLE_CHOICE },
  { label: 'Multiple Choice', value: QuestionTypeEnum.MULTIPLE_CHOICE }
];
