import { useCallback } from 'react';

import Defaults from '../../constants/Defaults';
import { useApiGet, useApiMutation } from './Api.hooks';
import { Choice } from '../../types/Choice';
import { HttpMethod } from './Api';
import { Question } from '../../types/Question';

export function useGetChoice(choiceId: string) {
  return useApiGet<Choice | null>(null, 'choices/' + choiceId);
}

export function useGetChoicesByPage() {
  const { get: originalGet, data, ...rest } = useApiGet<[Choice[], number]>([[], 0], 'choices');

  const get = useCallback(
    async (params: { page?: number; query?: string; sort?: string; limit?: number; questionId?: number }) => {
      const defaults = {
        page: 0,
        limit: Defaults.LIST_PAGE_SIZE
      };
      await originalGet({ ...defaults, ...params });
    },
    [originalGet]
  );

  const [responseData, count] = data;
  return { ...rest, data: responseData, count, get };
}

export type ChoiceUpdateDTO = {
  choice: string;
  freetext: boolean;
  validateText: boolean;
  score: number;
  weighting: number;
  weight: number;
  question: Question;
};

export function useUpdateChoice(choiceId: string) {
  const { mutate, ...rest } = useApiMutation<Choice, ChoiceUpdateDTO>(null, HttpMethod.PATCH, 'choices/' + choiceId);

  const update = useCallback(
    async (choice: ChoiceUpdateDTO) => {
      await mutate(choice);
    },
    [mutate]
  );

  return { ...rest, update };
}

export type ChoiceCreateDTO = {
  choice: string;
  freetext: boolean;
  validateText: boolean;
  score: number;
  weighting: number;
  weight: number;
  question: Question;
};

export function useCreateChoice() {
  const { mutate, ...rest } = useApiMutation<Choice, ChoiceCreateDTO>(null, HttpMethod.POST, 'choices/');

  const create = useCallback(
    async (choice: ChoiceCreateDTO) => {
      await mutate(choice);
    },
    [mutate]
  );

  return { ...rest, create };
}

export function useDeleteChoice(choiceId: string) {
  const { mutate, ...rest } = useApiMutation<Choice, null>(null, HttpMethod.DELETE, 'choices/' + choiceId);

  const choiceDelete = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return { ...rest, choiceDelete };
}
