import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { Button } from '@rmwc/button';
import { Card } from '@rmwc/card';
import { CircularProgress } from '@rmwc/circular-progress';
import { Typography } from '@rmwc/typography';

import styled from '../../../styled-components';
import { useGetSurvey } from '../../../lib/api/Survey.hooks';
import theme from '../../../constants/Theme';
import { CollapsibleList, List, ListItem, ListItemMeta, ListItemPrimaryText, ListItemSecondaryText, ListItemText } from '@rmwc/list';
import BreadCrumbs from '../../../components/Breadcrumbs';
import EmptyData from '../../../components/EmptyData';
import { useError } from '../../../lib/error.hook';

type SurveyEditProps = RouteComponentProps<{ id: string; sid: string }>;

const Container = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  margin: 8px 0;
`;

const StyledCard = styled(Card)`
  display: flex;
  overflow: scroll;
`;

const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`;

const DetailsContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-start;

  & > * {
    margin: 5px;
  }
`;

const StyledLabel = styled(Typography)<{ color?: string }>`
  ${({ color }) => `
    color: ${color || 'black'};
  `}
`;

const StyledListItemPrimaryText = styled(ListItemPrimaryText)<{ color?: string; oneLine?: boolean }>`
  ${({ color }) => `
    color: ${color || 'black'};
  `}
`;

const StyledListItemSecondaryText = styled(ListItemSecondaryText)<{ color?: string }>`
  ${({ color }) => `
    color: ${color || 'black'};
  `}
`;

const ListHorizontalLine = styled.hr<{ color?: string }>`
  ${({ color }) => `
    flex: 1;
    margin: 0 32px;
    border: 1px dashed ${color || 'black'};
  `}
`;

const ListLeftText = styled.div<{ color?: string }>`
  ${({ color }) => `
    padding-right: 24px;
    font-size: 1.2em;
    color: ${color || 'black'};
    flex: 0 1;
  `}
`;

function SurveyEdit(props: SurveyEditProps) {
  const { history, match } = props;
  const { id: subscriberId, sid: surveyId } = match.params;
  const getSurvey = useGetSurvey(surveyId);
  const { get } = getSurvey;
  const { error: getError } = useError([getSurvey.error]);

  useEffect(() => {
    if (surveyId !== 'new') {
      get();
    }
  }, [get, surveyId]);

  const handleCancel = () => {
    history.goBack();
  };

  const onQuizClick = (quizId: number) => {
    history.push(`/cms/quizzes/${quizId}`);
  };

  const onSubscriberClick = (subscriberId: number) => {
    history.push(`/cms/subscribers/${subscriberId}`);
  };

  const onAnswerClick = (answerId: number) => {
    history.push(`/cms/subscribers/${subscriberId}/surveys/${surveyId}/answers/${answerId}`);
  };

  let ready = !getSurvey.isLoading;
  let initialValues = getError ? null : getSurvey.data;

  return (
    <Container>
      <BreadCrumbs
        crumbs={[
          { text: 'Subscribers', path: '/cms/subscribers' },
          { text: 'Surveys', path: `/cms/subscribers/${subscriberId}/surveys` },
          { text: 'View', path: '' }
        ]}
      />
      <TopBar>
        <Typography use='headline5'>Survey</Typography>
      </TopBar>
      <StyledCard>
        {!ready && (
          <Spinner>
            <CircularProgress size='large' />
          </Spinner>
        )}
        {ready && !initialValues && <EmptyData onBackClick={handleCancel} isNoData />}
        {ready && initialValues && (
          <DetailsContainer>
            <List twoLine dense>
              <ListItem disabled>
                <ListItemText>
                  <StyledListItemPrimaryText>{initialValues.id}</StyledListItemPrimaryText>
                  <StyledListItemSecondaryText color={theme.primary}>ID</StyledListItemSecondaryText>
                </ListItemText>
              </ListItem>

              <ListItem disabled>
                <ListItemText>
                  <StyledListItemPrimaryText>{initialValues.score.toFixed(2)}</StyledListItemPrimaryText>
                  <StyledListItemSecondaryText color={theme.primary}>Score</StyledListItemSecondaryText>
                </ListItemText>
              </ListItem>

              <ListItem onClick={() => onQuizClick(initialValues!.quiz.id)}>
                <ListItemText>
                  <StyledListItemPrimaryText>{initialValues.quiz.name}</StyledListItemPrimaryText>
                  <StyledListItemSecondaryText color={theme.primary}>Quiz</StyledListItemSecondaryText>
                </ListItemText>
                <ListItemMeta icon='link' />
              </ListItem>

              <ListItem onClick={() => onSubscriberClick(initialValues!.subscriber.id)}>
                <ListItemText>
                  <StyledListItemPrimaryText>{initialValues.subscriber.name}</StyledListItemPrimaryText>
                  <StyledListItemSecondaryText color={theme.primary}>Subscriber</StyledListItemSecondaryText>
                </ListItemText>
                <ListItemMeta icon='link' />
              </ListItem>
            </List>

            <CollapsibleList
              startOpen
              handle={
                <ListItem>
                  <ListItemText>
                    <StyledLabel use='body1' tag='span' color={theme.primary}>
                      Answers
                    </StyledLabel>
                  </ListItemText>
                  <ListHorizontalLine color={theme.gainsboro} />
                  <ListItemMeta icon='chevron_right' />
                </ListItem>
              }
            >
              <List twoLine dense>
                {initialValues.answers
                  .sort((a, b) => a.question.weight - b.question.weight)
                  .map(answer => (
                    <ListItem key={answer.id} onClick={() => onAnswerClick(answer.id)}>
                      <ListLeftText color={theme.grey}>#{answer.question.weight}</ListLeftText>
                      <ListItemText>
                        <ListItemPrimaryText>
                          <span style={{ color: theme.primary }}>Score: </span> {answer.score.toFixed(2)}
                        </ListItemPrimaryText>
                        <ListItemSecondaryText>
                          <span>{answer.question.question}</span>
                          <span style={{ paddingLeft: 8, paddingRight: 8 }}>~</span>
                          <span style={{ fontWeight: 'bold' }}>"{answer.answer}"</span>
                        </ListItemSecondaryText>
                      </ListItemText>
                      <ListItemMeta icon='link' />
                    </ListItem>
                  ))}
              </List>
            </CollapsibleList>

            <ButtonContainer>
              <Buttons>
                <Button theme='secondary' type='button' label='Go Back' onClick={handleCancel} />
              </Buttons>
            </ButtonContainer>
          </DetailsContainer>
        )}
      </StyledCard>
    </Container>
  );
}

export default withRouter(SurveyEdit);
