import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { Button } from '@rmwc/button';
import { Card } from '@rmwc/card';
import { CircularProgress } from '@rmwc/circular-progress';
import { Typography } from '@rmwc/typography';

import styled from '../../../styled-components';
import { useGetAnswer } from '../../../lib/api/Answer.hooks';
import BreadCrumbs from '../../../components/Breadcrumbs';
import theme from '../../../constants/Theme';
import { List, ListItem, ListItemMeta, ListItemPrimaryText, ListItemSecondaryText, ListItemText } from '@rmwc/list';
import EmptyData from '../../../components/EmptyData';

type AnswerEditProps = RouteComponentProps<{ id: string; sid: string; aid: string }>;

const Container = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  margin: 8px 0;
`;

const StyledCard = styled(Card)`
  display: flex;
  overflow: scroll;
`;

const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`;

const DetailsContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-start;

  & > * {
    margin: 5px;
  }
`;

const StyledListItemPrimaryText = styled(ListItemPrimaryText)<{ color?: string; oneLine?: boolean }>`
  ${({ color }) => `
    color: ${color || 'black'};
  `}
`;

const StyledListItemSecondaryText = styled(ListItemSecondaryText)<{ color?: string }>`
  ${({ color }) => `
    color: ${color || 'black'};
  `}
`;

function AnswerEdit(props: AnswerEditProps) {
  const { history, match } = props;
  const { id: subscriberId, sid: surveyId, aid: answerId } = match.params;
  const getAnswer = useGetAnswer(answerId);
  const { get } = getAnswer;

  useEffect(() => {
    if (answerId !== 'new') {
      get();
    }
  }, [get, answerId]);

  const handleCancel = () => {
    history.goBack();
  };

  const onSurveyClick = (surveyId: number) => {
    history.push(`/cms/subscribers/${subscriberId}/surveys/${surveyId}`);
  };

  const onQuestionClick = (quizId: number, questionId: number) => {
    history.push(`/cms/quizzes/${quizId}/questions/${questionId}`);
  };

  let ready = !getAnswer.isLoading;
  let initialValues = getAnswer.data;

  return (
    <Container>
      <BreadCrumbs
        crumbs={[
          { text: 'Subscribers', path: '/cms/subscribers' },
          { text: 'Surveys', path: `/cms/subscribers/${subscriberId}/surveys` },
          { text: 'Answers', path: `/cms/subscribers/${subscriberId}/surveys/${surveyId}/answers` },
          { text: 'View', path: '' }
        ]}
      />
      <TopBar>
        <Typography use='headline5'>Answer</Typography>
      </TopBar>
      <StyledCard>
        {!ready && (
          <Spinner>
            <CircularProgress size='large' />
          </Spinner>
        )}
        {ready && !initialValues && <EmptyData onBackClick={handleCancel} isNoData />}
        {ready && initialValues && (
          <DetailsContainer>
            <List twoLine dense>
              <ListItem disabled>
                <ListItemText>
                  <StyledListItemPrimaryText>{initialValues.id}</StyledListItemPrimaryText>
                  <StyledListItemSecondaryText color={theme.primary}>ID</StyledListItemSecondaryText>
                </ListItemText>
              </ListItem>

              <ListItem disabled>
                <ListItemText>
                  <StyledListItemPrimaryText>{initialValues.answer}</StyledListItemPrimaryText>
                  <StyledListItemSecondaryText color={theme.primary}>Answer</StyledListItemSecondaryText>
                </ListItemText>
              </ListItem>

              <ListItem disabled>
                <ListItemText>
                  <StyledListItemPrimaryText>{initialValues.score.toFixed(2)}</StyledListItemPrimaryText>
                  <StyledListItemSecondaryText color={theme.primary}>Score</StyledListItemSecondaryText>
                </ListItemText>
              </ListItem>

              <ListItem onClick={() => onSurveyClick(initialValues!.survey.id)}>
                <ListItemText>
                  <StyledListItemPrimaryText>{initialValues.survey.id}</StyledListItemPrimaryText>
                  <StyledListItemSecondaryText color={theme.primary}>Survey</StyledListItemSecondaryText>
                </ListItemText>
                <ListItemMeta icon='link' />
              </ListItem>

              <ListItem onClick={() => onQuestionClick(initialValues!.survey.quiz.id, initialValues!.question.id)}>
                <ListItemText>
                  <StyledListItemPrimaryText>{initialValues.question.question}</StyledListItemPrimaryText>
                  <StyledListItemSecondaryText color={theme.primary}>Question</StyledListItemSecondaryText>
                </ListItemText>
                <ListItemMeta icon='link' />
              </ListItem>
            </List>

            <ButtonContainer>
              <Buttons>
                <Button theme='secondary' type='button' label='Go Back' onClick={handleCancel} />
              </Buttons>
            </ButtonContainer>
          </DetailsContainer>
        )}
      </StyledCard>
    </Container>
  );
}

export default withRouter(AnswerEdit);
