import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import { format } from 'date-fns';

import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';
import { DataTableBody, DataTableHead, DataTableRow, DataTableCell } from '@rmwc/data-table';
import { Typography } from '@rmwc/typography';

import styled from '../../../styled-components';
import Pagination from '../../../components/Pagination';
import Defaults from '../../../constants/Defaults';
import { FullWidthDataTable, FullWidthDataTableContent, InvertedDataTableHeadCell } from '../../../components/Table';
import parseSort from '../../../lib/parseSort';
import { useGetServicesByPage } from '../../../lib/api/Service.hooks';
import BreadCrumbs from '../../../components/Breadcrumbs';
import EmptyData from '../../../components/EmptyData';

type ServiceListProps = RouteComponentProps;

const Container = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  overflow: scroll;
`;

const FullWidthBar = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px 0;
  justify-content: space-between;
  align-items: center;
`;

const PointerDataTableRow = styled(DataTableRow)`
  cursor: pointer;
`;

const StyledPagination = styled(Pagination)`
  align-self: flex-end;
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
`;

function Spinner() {
  return (
    <SpinnerContainer>
      <CircularProgress size='large' />
    </SpinnerContainer>
  );
}
function ServiceList(props: ServiceListProps) {
  const { history } = props;
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [sort, setSort] = useQueryParam('sort', StringParam);

  const { data, count, isLoading, error, get } = useGetServicesByPage();

  useEffect(() => {
    get({
      page: Number(page) || 0,
      sort: String(sort || '')
    });
  }, [get, page, sort]);

  const editService = (serviceId: string) => {
    history.push(`/cms/services/${serviceId}`);
  };

  const setNewSort = (column: string, direction: number | null) => {
    const newSort = direction ? `${column} ${direction > 0 ? 'DESC' : 'ASC'}` : undefined;
    setSort(newSort);
  };

  const setNewPage = (page: number) => {
    page = page - 1;
    setPage(page);
  };

  const showSchedules = (serviceId: string) => {
    history.push(`/cms/services/${serviceId}/schedules`);
  };

  let sortObj;
  if (sort) {
    sortObj = parseSort(sort as string);
  }
  sortObj = sortObj || { column: '', direction: null };

  return (
    <Container>
      <BreadCrumbs crumbs={[{ text: 'Services', path: '' }]} />

      <FullWidthBar>
        <Typography use='headline5'>Services</Typography>
        <Button unelevated label='Add New Service' icon='settings' {...{ tag: Link, to: '/cms/services/new' }} />
      </FullWidthBar>

      <FullWidthDataTable>
        <FullWidthDataTableContent>
          <DataTableHead>
            <DataTableRow>
              <InvertedDataTableHeadCell
                alignEnd
                sort={sortObj.column === 'id' ? sortObj.direction : null}
                onSortChange={dir => {
                  setNewSort('id', dir);
                }}
              >
                ID
              </InvertedDataTableHeadCell>
              <InvertedDataTableHeadCell
                sort={sortObj.column === 'branch.id' ? sortObj.direction : null}
                onSortChange={dir => {
                  setNewSort('branch.id', dir);
                }}
              >
                Branch ID
              </InvertedDataTableHeadCell>
              <InvertedDataTableHeadCell
                sort={sortObj.column === 'name' ? sortObj.direction : null}
                onSortChange={dir => {
                  setNewSort('name', dir);
                }}
              >
                Name
              </InvertedDataTableHeadCell>
              <InvertedDataTableHeadCell
                sort={sortObj.column === 'price' ? sortObj.direction : null}
                onSortChange={dir => {
                  setNewSort('price', dir);
                }}
              >
                Price
              </InvertedDataTableHeadCell>
              <InvertedDataTableHeadCell
                sort={sortObj.column === 'branch.name' ? sortObj.direction : null}
                onSortChange={dir => {
                  setNewSort('branch.name', dir);
                }}
              >
                Branch Name
              </InvertedDataTableHeadCell>
              <InvertedDataTableHeadCell
                sort={sortObj.column === 'created' ? sortObj.direction : null}
                onSortChange={dir => {
                  setNewSort('created', dir);
                }}
              >
                Created
              </InvertedDataTableHeadCell>
              <InvertedDataTableHeadCell
                sort={sortObj.column === 'updated' ? sortObj.direction : null}
                onSortChange={dir => {
                  setNewSort('updated', dir);
                }}
              >
                Updated
              </InvertedDataTableHeadCell>
              <InvertedDataTableHeadCell />
            </DataTableRow>
          </DataTableHead>
          <DataTableBody>
            {!isLoading &&
              !error &&
              data &&
              data.map(row => (
                <PointerDataTableRow key={row.id} onClick={() => editService(row.id)}>
                  <DataTableCell>{row.id}</DataTableCell>
                  <DataTableCell>{row.branch.id}</DataTableCell>
                  <DataTableCell>{row.name}</DataTableCell>
                  <DataTableCell>{row.price}</DataTableCell>
                  <DataTableCell>{row.branch.name}</DataTableCell>
                  <DataTableCell>{format(new Date(row.created), 'MMM. dd, yyyy - h:mm:ss a')}</DataTableCell>
                  <DataTableCell>{format(new Date(row.updated), 'MMM. dd, yyyy - h:mm:ss a')}</DataTableCell>
                  <DataTableCell>
                    <Button
                      label='Show Schedules'
                      onClick={event => {
                        event.stopPropagation();
                        showSchedules(row.id);
                      }}
                    />
                  </DataTableCell>
                </PointerDataTableRow>
              ))}
          </DataTableBody>
        </FullWidthDataTableContent>
      </FullWidthDataTable>

      {isLoading && <Spinner />}
      {!isLoading && !error && data && data.length === 0 && <EmptyData />}

      <FullWidthBar>
        <Typography use='subtitle1'>Total: {count}</Typography>
        <StyledPagination
          current={Number(page) + 1 || 1}
          total={Math.ceil(count / Defaults.LIST_PAGE_SIZE)}
          onPageChange={(page: number) => setNewPage(page)}
        />
      </FullWidthBar>
    </Container>
  );
}

export default withRouter(ServiceList);
