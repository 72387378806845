import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { format } from 'date-fns';

import { Button } from '@rmwc/button';
import { Card } from '@rmwc/card';
import { CircularProgress } from '@rmwc/circular-progress';
import { Typography } from '@rmwc/typography';

import styled from '../../../styled-components';
import { useGetSubscriber } from '../../../lib/api/Subscriber.hooks';
import BreadCrumbs from '../../../components/Breadcrumbs';
import { CollapsibleList, List, ListItem, ListItemMeta, ListItemPrimaryText, ListItemSecondaryText, ListItemText } from '@rmwc/list';
import theme from '../../../constants/Theme';
import EmptyData from '../../../components/EmptyData';
import { useError } from '../../../lib/error.hook';

type SubscriberEditProps = RouteComponentProps<{ id: string }>;

const Container = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  margin: 8px 0;
`;

const StyledCard = styled(Card)`
  display: flex;
  overflow: scroll;
`;

const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`;

const DetailsContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-start;

  & > * {
    margin: 5px;
  }
`;

const StyledLabel = styled(Typography)<{ color?: string }>`
  ${({ color }) => `
    color: ${color || 'black'};
  `}
`;

const StyledListItemPrimaryText = styled(ListItemPrimaryText)<{ color?: string; oneLine?: boolean }>`
  ${({ color }) => `
    color: ${color || 'black'};
  `}
`;

const StyledListItemSecondaryText = styled(ListItemSecondaryText)<{ color?: string }>`
  ${({ color }) => `
    color: ${color || 'black'};
  `}
`;

const ListHorizontalLine = styled.hr<{ color?: string }>`
  ${({ color }) => `
    flex: 1;
    margin: 0 32px;
    border: 1px dashed ${color || 'black'};
  `}
`;

const ListLeftText = styled.div<{ color?: string }>`
  ${({ color }) => `
    padding-right: 24px;
    font-size: 1.2em;
    color: ${color || 'black'};
    flex: 0 1;
    text-wrap: no-wrap;
  `}
`;

function SubscriberEdit(props: SubscriberEditProps) {
  const { history, match } = props;
  const subscriberId = match.params.id;
  const getSubscriber = useGetSubscriber(subscriberId);
  const { get } = getSubscriber;
  const { error: getError } = useError([getSubscriber.error]);

  useEffect(() => {
    if (subscriberId !== 'new') {
      get();
    }
  }, [get, subscriberId]);

  const handleCancel = () => {
    history.goBack();
  };

  const onSurveyClick = (surveyId: number) => {
    history.push(`/cms/subscribers/${subscriberId}/surveys/${surveyId}`);
  };

  let ready = !getSubscriber.isLoading;
  let initialValues = getError ? null : getSubscriber.data;

  return (
    <Container>
      <BreadCrumbs crumbs={[{ text: 'Subscribers', path: '/cms/subscribers' }, { text: 'View', path: '' }]} />
      <TopBar>
        <Typography use='headline5'>Subscriber</Typography>
      </TopBar>
      <StyledCard>
        {!ready && (
          <Spinner>
            <CircularProgress size='large' />
          </Spinner>
        )}
        {ready && !initialValues && <EmptyData onBackClick={handleCancel} isNoData />}
        {ready && initialValues && (
          <DetailsContainer>
            <List twoLine dense>
              <ListItem disabled>
                <ListItemText>
                  <StyledListItemPrimaryText>{initialValues.id}</StyledListItemPrimaryText>
                  <StyledListItemSecondaryText color={theme.primary}>ID</StyledListItemSecondaryText>
                </ListItemText>
              </ListItem>

              <ListItem disabled>
                <ListItemText>
                  <StyledListItemPrimaryText>{initialValues.name}</StyledListItemPrimaryText>
                  <StyledListItemSecondaryText color={theme.primary}>Name</StyledListItemSecondaryText>
                </ListItemText>
              </ListItem>

              <ListItem disabled>
                <ListItemText>
                  <StyledListItemPrimaryText>{initialValues.ageGroup}</StyledListItemPrimaryText>
                  <StyledListItemSecondaryText color={theme.primary}>Age Group</StyledListItemSecondaryText>
                </ListItemText>
              </ListItem>

              <ListItem disabled>
                <ListItemText>
                  <StyledListItemPrimaryText>{initialValues.emailAddress || '--'}</StyledListItemPrimaryText>
                  <StyledListItemSecondaryText color={theme.primary}>Email Address</StyledListItemSecondaryText>
                </ListItemText>
              </ListItem>

              <ListItem disabled>
                <ListItemText>
                  <StyledListItemPrimaryText>{initialValues.dentrixId || '--'}</StyledListItemPrimaryText>
                  <StyledListItemSecondaryText color={theme.primary}>Dentrix ID</StyledListItemSecondaryText>
                </ListItemText>
              </ListItem>

              <ListItem disabled>
                <ListItemText>
                  <StyledListItemPrimaryText>{initialValues.shopifyId || '--'}</StyledListItemPrimaryText>
                  <StyledListItemSecondaryText color={theme.primary}>Shopify ID</StyledListItemSecondaryText>
                </ListItemText>
              </ListItem>

              <ListItem disabled>
                <ListItemText>
                  <StyledListItemPrimaryText>
                    {format(new Date(initialValues.created), 'MMM. dd, yyyy - h:mm:ss a')}
                  </StyledListItemPrimaryText>
                  <StyledListItemSecondaryText color={theme.primary}>Created</StyledListItemSecondaryText>
                </ListItemText>
              </ListItem>
            </List>
            <CollapsibleList
              startOpen
              handle={
                <ListItem>
                  <ListItemText>
                    <StyledLabel use='body1' tag='span' color={theme.primary}>
                      Surveys
                    </StyledLabel>
                  </ListItemText>
                  <ListHorizontalLine color={theme.gainsboro} />
                  <ListItemMeta icon='chevron_right' />
                </ListItem>
              }
            >
              <List twoLine dense>
                {initialValues.surveys.map(survey => (
                  <ListItem key={survey.id} onClick={() => onSurveyClick(survey.id)}>
                    <ListLeftText color={theme.grey}>ID:&nbsp;{survey.id}</ListLeftText>
                    <ListItemText>
                      <ListItemPrimaryText>
                        <span style={{ color: theme.primary }}>Score: </span> {survey.score.toFixed(2)}
                      </ListItemPrimaryText>
                      <ListItemSecondaryText>
                        <span>{survey.quiz.name}</span>
                      </ListItemSecondaryText>
                    </ListItemText>
                    <ListItemMeta icon='link' />
                  </ListItem>
                ))}
              </List>
            </CollapsibleList>

            <ButtonContainer>
              <Buttons>
                <Button theme='secondary' type='button' label='Go Back' onClick={handleCancel} />
              </Buttons>
            </ButtonContainer>
          </DetailsContainer>
        )}
      </StyledCard>
    </Container>
  );
}

export default withRouter(SubscriberEdit);
