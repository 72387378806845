import { parse, format, isValid, differenceInMinutes, add, addMinutes } from 'date-fns';
import { isNil } from 'lodash';

const PLACEHOLDER = {
  YEAR: 2021,
  MONTH: 0,
  DATE: 1
};

export const INVALID_INTEGER_CHARS = ['e', 'E', '.', '+'];
export const INVALID_DECIMAL_CHARS = ['e', 'E', '+'];
export const DAYS_OF_WEEK = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

/**
 * Prevents pasting texts to an input field that only needs numbers.
 */
export function handlePasteNumber(e: ClipboardEvent, cb: (result: number) => void) {
  e.preventDefault();
  const clipboard = e && e.clipboardData;
  if (!clipboard) {
    return '';
  }

  const text = clipboard.getData('Text');
  const nums = text.match(/[0-9]/g);

  if (isNil(nums)) {
    return '';
  }

  const res = parseInt(nums.join(''));
  cb(res);
}

/**
 * Formats time from DB format to readable time. The result string looks like this: "01:30 PM"
 */
export function formatTime(dbTime?: string): string {
  if (!dbTime) {
    return '';
  }

  const dateFormat = 'hh:mm a';
  const dbDateFormat = 'HH:mm:ss';
  const parsedDate = parse(dbTime, dbDateFormat, new Date(PLACEHOLDER.YEAR, PLACEHOLDER.MONTH, PLACEHOLDER.DATE));

  if (!isValid(parsedDate)) {
    return '';
  }

  return format(parsedDate, dateFormat);
}

/**
 * Formats readable time to DB format. The result string looks like this: "13:30:00"
 */
export function formatToDBTime(time?: string): string {
  if (!time) {
    return '';
  }

  const dateFormat = 'hh:mm a';
  const dbDateFormat = 'HH:mm:ss';
  const parsedDate = parse(time, dateFormat, new Date(PLACEHOLDER.YEAR, PLACEHOLDER.MONTH, PLACEHOLDER.DATE));

  if (!isValid(parsedDate)) {
    return '';
  }

  return format(parsedDate, dbDateFormat);
}

export function addMinutesToTime(time: string, minutes: number): string {
  if (!time) {
    return '';
  }

  const dateFormat = 'hh:mm a';
  let parsedDate = parse(time, dateFormat, new Date(PLACEHOLDER.YEAR, PLACEHOLDER.MONTH, PLACEHOLDER.DATE));

  if (!isValid(parsedDate)) {
    return '';
  }

  parsedDate = addMinutes(parsedDate, minutes);

  return format(parsedDate, dateFormat);
}

export type GetTimesOptions = {
  step?: number;
  startTime?: string;
  endTime?: string;
};
/**
 * Generates a list of time from startTime to endTime.
 * @param {number} step - The interval of the whole time list. The default is 15.
 * @param {number} startTime - The start time of the list. The default is '12:00 AM'.
 * @param {number} endTime - The end time of the list.
 */
export function getTimes(options?: GetTimesOptions): string[] {
  if (!options) {
    options = {
      step: 15,
      startTime: '12:00 AM'
    };
  }

  if (options && !options.step) {
    options.step = 15;
  }

  if (options && !options.startTime) {
    options.startTime = '12:00 AM';
  }

  let times: string[] = [];
  let start: Date = new Date();
  let end: Date = new Date();

  if (options.startTime) {
    start = parse(options.startTime, 'hh:mm a', new Date(PLACEHOLDER.YEAR, PLACEHOLDER.MONTH, PLACEHOLDER.DATE));

    if (!isValid(start)) {
      console.error('Invalid options.startTime format. Example of a valid format is: "04:30 AM", "12:00 PM"');
      return times;
    }
  }

  if (options.endTime) {
    end = parse(options.endTime, 'hh:mm a', new Date(PLACEHOLDER.YEAR, PLACEHOLDER.MONTH, PLACEHOLDER.DATE));

    if (!isValid(end)) {
      console.error('Invalid options.endTime format. Example of a valid format is: "04:30 AM", "12:00 PM"');
      return times;
    }
  }

  let diff = differenceInMinutes(end, start);

  if (diff < 0) {
    console.error('Invalid options.endTime. It should be more than the startTime');
  }

  do {
    diff = differenceInMinutes(end, start);
    times.push(format(start, 'hh:mm a'));
    start = add(start, { minutes: options.step });
  } while (start.getDate() === PLACEHOLDER.DATE && diff > 0);

  return times;
}
