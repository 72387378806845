import React from 'react';
import { Route, Switch } from 'react-router';
import ScheduleEdit from './ScheduleEdit';
import ScheduleList from './ScheduleList';

function ScheduleModule() {
  return (
    <Switch>
      <Route exact path='/cms/schedules/' component={ScheduleList} />
      <Route exact path='/cms/schedules/:id' component={ScheduleEdit} />
      <Route exact path='/cms/schedules/new' component={ScheduleEdit} />
    </Switch>
  );
}

export default ScheduleModule;
