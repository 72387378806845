import { useCallback } from 'react';

import Defaults from '../../constants/Defaults';
import { useApiGet } from './Api.hooks';
import { Subscriber } from '../../types/Subscriber';

export function useGetSubscriber(subscriberId: string) {
  return useApiGet<Subscriber | null>(null, 'subscribers/' + subscriberId);
}

export function useGetSubscribersByPage() {
  const { get: originalGet, data, ...rest } = useApiGet<[Subscriber[], number]>([[], 0], 'subscribers');

  const get = useCallback(
    async (params?: { page?: number; query?: string; sort?: string; limit?: number }) => {
      const defaults = {
        page: 0,
        limit: Defaults.LIST_PAGE_SIZE
      };
      await originalGet({ ...defaults, ...params });
    },
    [originalGet]
  );

  const [responseData, count] = data;
  return { ...rest, data: responseData, count, get };
}
