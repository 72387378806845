import React, { useEffect, useMemo, useState } from 'react';

import SelectField from './SelectField';
import { formatTime, getTimes, GetTimesOptions } from '../constants/Helpers';

type OptionType = {
  label: string;
  value: string;
  isDisabled?: boolean;
};

type BasicTimePickerProps = {
  defaultValue: string;
  name: string;
  label: string;
  placeholder: string;
  invalid?: boolean | string;
  helpText?: boolean | string;
  getTimeOptions?: GetTimesOptions;
  onChange?: (timeOption: OptionType) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};
function BasicTimePicker(props: Partial<BasicTimePickerProps>) {
  const [timesOptions, setTimesOptions] = useState<OptionType[]>([]);
  const [selected, setSelected] = useState<OptionType | undefined>();

  useEffect(() => {
    // initialize timeOptions
    setTimesOptions(
      getTimes(props.getTimeOptions).map((time: string) => ({
        label: time,
        value: time
      }))
    );
  }, [props.getTimeOptions]);

  useEffect(() => {
    // initialize selected
    setSelected(timesOptions.find(timeOption => timeOption.value === formatTime(props.defaultValue)));
  }, [props.defaultValue, timesOptions]);

  useMemo(() => {
    if (!selected) {
      const defaultValueOption = {
        label: formatTime(props.defaultValue),
        value: formatTime(props.defaultValue),
        isDisabled: true
      };
      const separator = { label: '---', value: '', isDisabled: true };
      setTimesOptions([defaultValueOption, separator, ...timesOptions]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultValue, selected]);

  return (
    <>
      <SelectField
        isMulti={false}
        allowCreate={true}
        defaultValue={selected}
        value={selected}
        options={timesOptions}
        name={props.name}
        onChange={(time: OptionType) => {
          setSelected(time);
          if (props.onChange) {
            props.onChange(time);
          }
        }}
        onBlur={props.onBlur}
        placeholder={props.placeholder}
        label={props.label}
        changeOptionLabel='label'
        changeOptionValue='value'
        invalid={props.invalid}
        helpText={props.helpText}
      />
    </>
  );
}

export default BasicTimePicker;
