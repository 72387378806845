import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import { format } from 'date-fns';

import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';
import { DataTableBody, DataTableHead, DataTableRow, DataTableCell } from '@rmwc/data-table';
import { Dialog, DialogActions, DialogButton, DialogContent, DialogTitle } from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';

import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import styled from '../../../styled-components';
import BreadCrumbs from '../../../components/Breadcrumbs';
import EmptyData from '../../../components/EmptyData';
import Pagination from '../../../components/Pagination';
import SelectField from '../../../components/SelectField';
import { FullWidthDataTable, FullWidthDataTableContent, InvertedDataTableHeadCell } from '../../../components/Table';

import Defaults from '../../../constants/Defaults';
import { formatTime } from '../../../constants/Helpers';
import { ErrorMessages } from '../../../constants/Strings';
import parseSort from '../../../lib/parseSort';
import CmsSnackbarQueue from '../../../lib/CmsSnackbarQueue';
import { useError } from '../../../lib/error.hook';

import { useGetServiceSchedulesByPage, useCopyServiceSchedules } from '../../../lib/api/ServiceSchedule.hooks';
import { useGetServicesByPage } from '../../../lib/api/Service.hooks';

type ServiceScheduleListProps = RouteComponentProps<{ id: string }>;
type ScheduleCopyFormType = {
  serviceIds: string[];
};

const SERVICES_PAGE_SIZE = 500;
const ScheduleCopySchema = Yup.object().shape({
  serviceIds: Yup.array(Yup.string()).required(ErrorMessages.REQUIRED_SERVICES)
});

const Container = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  overflow: scroll;
`;

const FullWidthBar = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px 0;
  justify-content: space-between;
  align-items: center;
`;

const PointerDataTableRow = styled(DataTableRow)`
  cursor: pointer;
`;

const StyledPagination = styled(Pagination)`
  align-self: flex-end;
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  row-gap: 5px;

  button {
    margin-left: 10px;
  }
`;

const FormContainer = styled(Form)`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & > * {
    flex-shrink: 0;
  }
`;

const CopyDialog = styled(Dialog)`
  @media (max-width: ${Defaults.MEDIA_BREAKPOINTS.xs}px) {
    .mdc-dialog__container {
      width: 80%;
    }
    .mdc-dialog__surface {
      width: 100%;
    }
  }
`;

const CopyDialogContent = styled(DialogContent)`
  @media (min-width: ${Defaults.MEDIA_BREAKPOINTS.xs}px) {
    min-width: 500px;
  }
`;

const CopyFormSpinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 125px;
`;

function Spinner() {
  return (
    <SpinnerContainer>
      <CircularProgress size='large' />
    </SpinnerContainer>
  );
}
function ServiceScheduleList(props: ServiceScheduleListProps) {
  const { history, match } = props;
  const serviceId = match.params.id;
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [sort, setSort] = useQueryParam('sort', StringParam);

  const { data, count, isLoading, error, get } = useGetServiceSchedulesByPage();
  const getServicesByPage = useGetServicesByPage();
  const copyServiceSchedules = useCopyServiceSchedules();
  useError([error, getServicesByPage.error, copyServiceSchedules.error]);

  const [openCopyDialog, setOpenCopyDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formRef = useRef<FormikProps<ScheduleCopyFormType>>(null);
  const servicesRef = useRef<any>(null);

  useEffect(() => {
    if (openCopyDialog) {
      getServicesByPage.get({
        limit: SERVICES_PAGE_SIZE,
        excludeScheduled: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openCopyDialog, getServicesByPage.get]);

  useEffect(() => {
    get({
      page: Number(page) || 0,
      sort: String(sort || ''),
      serviceId
    });
  }, [get, page, sort, serviceId]);

  useEffect(() => {
    if (copyServiceSchedules.data && !copyServiceSchedules.error && !copyServiceSchedules.isLoading) {
      setOpenCopyDialog(false);
      CmsSnackbarQueue.notify({
        title: `Service Schedules were copied successfully.`
      });

      const form = formRef.current;
      form && form.resetForm();

      const services = servicesRef.current;
      services && services.select.clearValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copyServiceSchedules.data, copyServiceSchedules.error, copyServiceSchedules.isLoading]);

  const editServiceSchedule = (serviceScheduleId: number) => {
    history.push(`/cms/services/${serviceId}/schedules/${serviceScheduleId}`);
  };

  const setNewSort = (column: string, direction: number | null) => {
    const newSort = direction ? `${column} ${direction > 0 ? 'DESC' : 'ASC'}` : undefined;
    setSort(newSort);
  };

  const setNewPage = (page: number) => {
    page = page - 1;
    setPage(page);
  };

  let sortObj;
  if (sort) {
    sortObj = parseSort(sort as string);
  }
  sortObj = sortObj || { column: '', direction: null };

  const handleSubmit = async (values: ScheduleCopyFormType) => {
    setIsSubmitting(true);
    await copyServiceSchedules.copy({
      originServiceId: serviceId,
      copyServiceIds: values.serviceIds
    });
    setIsSubmitting(false);
  };

  const ready = !getServicesByPage.isLoading;

  return (
    <Container>
      <BreadCrumbs
        crumbs={[
          { text: 'Services', path: `/cms/services` },
          { text: 'Schedules', path: `/cms/services/${serviceId}/schedules`, disabled: true }
        ]}
      />

      <FullWidthBar>
        <Typography use='headline5'>Service Schedules</Typography>
        <ButtonGroup>
          <Button
            unelevated
            label='Assign Schedule'
            icon='rate_review'
            {...{ tag: Link, to: `/cms/services/${serviceId}/schedules/new` }}
          />
          <Button unelevated label='Copy Schedules' icon='copy_all' onClick={() => setOpenCopyDialog(true)} disabled={!data.length} />
        </ButtonGroup>
      </FullWidthBar>

      <FullWidthDataTable>
        <FullWidthDataTableContent>
          <DataTableHead>
            <DataTableRow>
              <InvertedDataTableHeadCell
                alignEnd
                sort={sortObj.column === 'id' ? sortObj.direction : null}
                onSortChange={dir => {
                  setNewSort('id', dir);
                }}
              >
                ID
              </InvertedDataTableHeadCell>
              <InvertedDataTableHeadCell
                alignEnd
                sort={sortObj.column === 'schedule.id' ? sortObj.direction : null}
                onSortChange={dir => {
                  setNewSort('schedule.id', dir);
                }}
              >
                Schedule ID
              </InvertedDataTableHeadCell>
              <InvertedDataTableHeadCell
                sort={sortObj.column === 'schedule.day' ? sortObj.direction : null}
                onSortChange={dir => {
                  setNewSort('schedule.day', dir);
                }}
              >
                Day of the week
              </InvertedDataTableHeadCell>
              <InvertedDataTableHeadCell
                sort={sortObj.column === 'schedule.start' ? sortObj.direction : null}
                onSortChange={dir => {
                  setNewSort('schedule.start', dir);
                }}
              >
                Availability
              </InvertedDataTableHeadCell>
              <InvertedDataTableHeadCell
                sort={sortObj.column === 'created' ? sortObj.direction : null}
                onSortChange={dir => {
                  setNewSort('created', dir);
                }}
              >
                Created
              </InvertedDataTableHeadCell>
            </DataTableRow>
          </DataTableHead>
          <DataTableBody>
            {!isLoading &&
              !error &&
              data &&
              data.map(row => (
                <PointerDataTableRow key={row.id} onClick={() => editServiceSchedule(row.id)}>
                  <DataTableCell>{row.id}</DataTableCell>
                  <DataTableCell>{row.schedule.id}</DataTableCell>
                  <DataTableCell>{row.schedule.day}</DataTableCell>
                  <DataTableCell>
                    {formatTime(row.schedule.start)} &#8212; {formatTime(row.schedule.end)}
                  </DataTableCell>
                  <DataTableCell>{format(new Date(row.created), 'MMM. dd, yyyy - h:mm:ss a')}</DataTableCell>
                </PointerDataTableRow>
              ))}
          </DataTableBody>
        </FullWidthDataTableContent>
      </FullWidthDataTable>

      {isLoading && <Spinner />}
      {!isLoading && !error && data && data.length === 0 && <EmptyData />}

      <FullWidthBar>
        <Typography use='subtitle1'>Total: {count}</Typography>
        <StyledPagination
          current={Number(page) + 1 || 1}
          total={Math.ceil(count / Defaults.LIST_PAGE_SIZE)}
          onPageChange={(page: number) => setNewPage(page)}
        />
      </FullWidthBar>

      <CopyDialog
        open={openCopyDialog}
        onClose={event => {
          if (event.detail.action === 'accept') {
            const form = formRef.current;
            form && form.submitForm();
          } else {
            setOpenCopyDialog(false);
          }
        }}
      >
        <DialogTitle>Copy Schedules</DialogTitle>
        <CopyDialogContent>
          {!ready ? (
            <CopyFormSpinner>
              <CircularProgress size='large' />
            </CopyFormSpinner>
          ) : (
            <Formik
              innerRef={formRef}
              initialValues={{ serviceIds: [] } as ScheduleCopyFormType}
              validationSchema={ScheduleCopySchema}
              validateOnBlur={true}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({ values, errors, touched, handleBlur, setFieldValue }) => {
                const serviceOptions = getServicesByPage.data.map(service => ({
                  value: service.id,
                  label: `${service.id} - ${service.name}`
                }));

                return (
                  <FormContainer>
                    <SelectField
                      selectRef={servicesRef}
                      isMulti
                      isSearchable
                      isClearable
                      autoFocus
                      defaultValue={serviceOptions.filter(serviceOption => values.serviceIds.includes(serviceOption.value))}
                      name='serviceIds'
                      options={serviceOptions}
                      onChange={(
                        serviceOption: {
                          value: string;
                          label: string;
                        }[]
                      ) => {
                        if (!serviceOption) {
                          setTimeout(() => setFieldValue('serviceIds', [], true));
                        } else {
                          const values = serviceOption.map(item => item.value);
                          setTimeout(() => setFieldValue('serviceIds', values, true));
                        }
                      }}
                      onBlur={handleBlur}
                      placeholder='Select Services'
                      label='Services'
                      changeOptionLabel='label'
                      changeOptionValue='value'
                      invalid={touched.serviceIds && errors.serviceIds}
                      helpText={
                        (touched.serviceIds && errors.serviceIds) || (
                          <div style={{ marginTop: 4 }}>
                            Applies the same schedule set to the selected service/s
                            <br />
                            (Only applies to services without existing schedules)
                          </div>
                        )
                      }
                    />
                  </FormContainer>
                );
              }}
            </Formik>
          )}
        </CopyDialogContent>
        <DialogActions>
          <DialogButton action='close'>Cancel</DialogButton>
          <DialogButton action='accept' disabled={!ready || isSubmitting}>
            Copy
          </DialogButton>
        </DialogActions>
      </CopyDialog>
    </Container>
  );
}

export default withRouter(ServiceScheduleList);
