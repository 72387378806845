import { Typography } from '@rmwc/typography';
import React, { CSSProperties, useState } from 'react';
import Select from 'react-select';

import theme from '../constants/Theme';
import styled from '../styled-components';

const selectStyles = (hasError: boolean) => ({
  valueContainer: (styles: CSSProperties) => ({
    ...styles,
    minHeight: '55px'
  }),
  control: (styles: CSSProperties, state: any) => ({
    ...styles,
    ...(hasError && { borderColor: 'red', boxShadow: state.menuIsOpen ? '0 0 0 1px red inset' : '0' }),
    '&:hover': {
      borderColor: hasError ? 'red' : state.isFocused ? styles.borderColor : theme.black
    }
  }),
  placeholder: (styles: CSSProperties) => ({
    ...styles,
    color: hasError ? 'red' : '#808080'
  }),
  menuPortal: (styles: CSSProperties) => ({
    ...styles,
    zIndex: 10
  })
});

const StyledLabel = styled(Typography)<{ color: string; invalid?: boolean }>`
  ${({ color, invalid }) => `
    margin-left: 16px;
    display: inline-block;
    color: ${invalid ? 'red' : color};
  `}
`;

function SelectField(props: any) {
  const { name, onBlur, label, invalid, helpText, changeOptionLabel, changeOptionValue, isSearchable = false, selectRef, ...rest } = props;
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <div>
      {label && (
        <StyledLabel use='caption' tag='span' color={isFocused ? theme.primary : theme.grey} invalid={invalid}>
          {label}
        </StyledLabel>
      )}
      <Select
        ref={selectRef}
        styles={selectStyles(invalid)}
        isSearchable={isSearchable}
        menuPortalTarget={document.body} // fixes menu being cut off by its parent. https://github.com/JedWatson/react-select/issues/404#issuecomment-433608100
        theme={themes => ({
          ...themes,
          colors: {
            ...themes.colors,
            primary: theme.primary,
            danger: theme.destructiveColor
          }
        })}
        onFocus={() => setIsFocused(true)}
        onBlur={(e: any) => {
          e.target.name = name;
          if (onBlur) {
            onBlur(e);
          }
          setIsFocused(false);
        }}
        getOptionLabel={(option: any) => option[changeOptionLabel]}
        getOptionValue={(option: any) => option[changeOptionValue]}
        {...rest}
      />
      <StyledLabel use='caption' tag='span' color={theme.grey} invalid={invalid}>
        {helpText || ''}
      </StyledLabel>
    </div>
  );
}

export default SelectField;
