import { useCallback } from 'react';

import Defaults from '../../constants/Defaults';
import { useApiGet, useApiMutation } from './Api.hooks';
import { HttpMethod } from './Api';
import { PracticeProcedure, ProcedureCategories, Service } from '../../types/Service';

export function useGetProcedureCategories() {
  return useApiGet<ProcedureCategories[]>([], 'services/procedure-categories');
}

export function useGetPracticeProcedures() {
  const { get: originalGet, data, ...rest } = useApiGet<PracticeProcedure[]>([], 'services/practice-procedures');

  const get = useCallback(
    async (params: { category: string }) => {
      await originalGet(params);
    },
    [originalGet]
  );

  return { ...rest, data, get };
}

export function useGetPracticeProcedure(id: string) {
  return useApiGet<PracticeProcedure | null>(null, 'services/practice-procedures/' + id);
}

export function useGetService(serviceId: string) {
  return useApiGet<Service | null>(null, 'services/' + serviceId);
}

export function useGetServicesByPage() {
  const { get: originalGet, data, ...rest } = useApiGet<[Service[], number]>([[], 0], 'services');

  const get = useCallback(
    async (params?: { page?: number; query?: string; sort?: string; limit?: number; excludeScheduled?: boolean }) => {
      const defaults = {
        page: 0,
        limit: Defaults.LIST_PAGE_SIZE
      };
      await originalGet({ ...defaults, ...params });
    },
    [originalGet]
  );

  const [responseData, count] = data;
  return { ...rest, data: responseData, count, get };
}

export type ServiceCreateDTO = { id: string; name: string; price: string; branchId: string };

export function useCreateService() {
  const { mutate, ...rest } = useApiMutation<Service, ServiceCreateDTO>(null, HttpMethod.POST, 'services');

  const create = useCallback(
    async (service: ServiceCreateDTO) => {
      await mutate(service);
    },
    [mutate]
  );

  return { ...rest, create };
}

export type ServiceUpdateDTO = ServiceCreateDTO;

export function useUpdateService(serviceId: string) {
  const { mutate, ...rest } = useApiMutation<Service, ServiceUpdateDTO>(null, HttpMethod.PATCH, 'services/' + serviceId);

  const update = useCallback(
    async (service: ServiceUpdateDTO) => {
      await mutate(service);
    },
    [mutate]
  );

  return { ...rest, update };
}

export function useDeleteService(serviceId: string) {
  const { mutate, ...rest } = useApiMutation<Service, null>(null, HttpMethod.DELETE, 'services/' + serviceId);

  const serviceDelete = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return { ...rest, serviceDelete };
}
