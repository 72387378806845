import { useCallback } from 'react';

import Defaults from '../../constants/Defaults';
import { useApiGet, useApiMutation } from './Api.hooks';
import { Schedule } from '../../types/Schedule';
import { HttpMethod } from './Api';

export function useGetSchedule(scheduleId: string) {
  return useApiGet<Schedule | null>(null, 'schedules/' + scheduleId);
}

export function useGetSchedulesByPage() {
  const { get: originalGet, data, ...rest } = useApiGet<[Schedule[], number]>([[], 0], 'schedules');

  const get = useCallback(
    async (params?: { page?: number; query?: string; sort?: string; limit?: number }) => {
      const defaults = {
        page: 0,
        limit: Defaults.LIST_PAGE_SIZE
      };
      await originalGet({ ...defaults, ...params });
    },
    [originalGet]
  );

  const [responseData, count] = data;

  return { ...rest, data: responseData, count, get };
}

export type ScheduleUpdateDTO = Pick<Schedule, 'day' | 'start' | 'end'>;

export function useUpdateSchedule(scheduleId: string) {
  const { mutate, ...rest } = useApiMutation<Schedule, ScheduleUpdateDTO>(null, HttpMethod.PATCH, 'schedules/' + scheduleId);

  const update = useCallback(
    async (schedule: ScheduleUpdateDTO) => {
      await mutate(schedule);
    },
    [mutate]
  );

  return { ...rest, update };
}

export type ScheduleCreateDTO = Pick<Schedule, 'day' | 'start' | 'end'>;

export function useCreateSchedule() {
  const { mutate, ...rest } = useApiMutation<Schedule, ScheduleCreateDTO>(null, HttpMethod.POST, 'schedules/');

  const create = useCallback(
    async (schedule: ScheduleCreateDTO) => {
      await mutate(schedule);
    },
    [mutate]
  );

  return { ...rest, create };
}

export function useDeleteSchedule(scheduleId: string) {
  const { mutate, ...rest } = useApiMutation<Schedule, null>(null, HttpMethod.DELETE, 'schedules/' + scheduleId);

  const scheduleDelete = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return { ...rest, scheduleDelete };
}
