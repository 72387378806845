import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { Button } from '@rmwc/button';
import { Card } from '@rmwc/card';
import { Checkbox } from '@rmwc/checkbox';
import { CircularProgress } from '@rmwc/circular-progress';
import { SimpleDialog } from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';

import { Form, Formik, FormikHelpers as FormikActions } from 'formik';
import * as Yup from 'yup';

import { ErrorMessages } from '../../../constants/Strings';
import TextField from '../../../components/TextField';
import { useGetChoice, useUpdateChoice, useCreateChoice, useDeleteChoice } from '../../../lib/api/Choice.hooks';
import CmsSnackbarQueue from '../../../lib/CmsSnackbarQueue';
import styled from '../../../styled-components';
import theme from '../../../constants/Theme';
import { Question } from '../../../types/Question';
import { useGetQuestion } from '../../../lib/api/Question.hooks';
import { Choice } from '../../../types/Choice';
import BreadCrumbs from '../../../components/Breadcrumbs';
import EmptyData from '../../../components/EmptyData';
import { useError } from '../../../lib/error.hook';
import { handlePasteNumber, INVALID_DECIMAL_CHARS, INVALID_INTEGER_CHARS } from '../../../constants/Helpers';

type ChoiceEditProps = RouteComponentProps<{ id: string; qid: string; cid: string }>;
type ChoiceFormType = {
  id: number | string;
  choice: string;
  freetext: boolean;
  validateText: boolean;
  score: number;
  weighting: number;
  weight: number;
  question: Question | null;
};

const ChoiceSchema = Yup.object().shape({
  choice: Yup.string().required(ErrorMessages.REQUIRED_CHOICE),
  freetext: Yup.boolean(),
  score: Yup.number().required(ErrorMessages.REQUIRED_SCORE),
  weighting: Yup.number()
    .required(ErrorMessages.REQUIRED_WEIGHTING)
    .min(0, ErrorMessages.POSITIVE_SCORE),
  weight: Yup.number()
    .required(ErrorMessages.REQUIRED_WEIGHT)
    .integer(ErrorMessages.POSITIVE_INTEGER_WEIGHT)
    .positive(ErrorMessages.POSITIVE_INTEGER_WEIGHT),
  question: Yup.object<Question>()
    .required(ErrorMessages.REQUIRED_QUESTION)
    .typeError(ErrorMessages.REQUIRED_QUESTION)
});

const Container = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-basis: auto;
`;

const TopBar = styled.div`
  margin: 8px 0;
`;

const StyledCard = styled(Card)`
  display: flex;
`;

const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`;

const FormContainer = styled(Form)`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  overflow: scroll;

  & > * {
    flex-shrink: 0;
  }
`;

const StyledTextField = styled(TextField)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-start;

  & > * {
    margin: 5px;
  }
`;

const DeleteButton = styled(Button)`
  &&& {
    color: ${theme.destructiveColor};
  }
`;

function ChoiceEdit(props: ChoiceEditProps) {
  const { history, match } = props;
  const { id: quizId, qid: questionId, cid: choiceId } = match.params;
  const getChoice = useGetChoice(choiceId);
  const updateChoice = useUpdateChoice(choiceId);
  const { get } = getChoice;
  const getQuestion = useGetQuestion(questionId);
  const createChoice = useCreateChoice();
  const deleteChoice = useDeleteChoice(choiceId);
  const [dialog, setDialog] = useState(false);
  const { error: getError } = useError([getChoice.error, getQuestion.error]);

  useError([updateChoice.error, createChoice.error, deleteChoice.error]);

  useEffect(() => {
    if (choiceId !== 'new') {
      get();
    }
  }, [get, choiceId]);

  useEffect(() => {
    getQuestion.get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getQuestion.get]);

  useEffect(() => {
    if (updateChoice.data && !updateChoice.error && !updateChoice.isLoading) {
      CmsSnackbarQueue.notify({
        title: `Choice saved successfully.`,
        actions: [
          {
            label: 'VIEW',
            onClick: () => history.push(`/cms/quizzes/${quizId}/questions/${questionId}/choices/${updateChoice.data!.id}`)
          }
        ]
      });

      // TODO this causes a warning. Fix warning.
      history.push(`/cms/quizzes/${quizId}/questions/${questionId}/choices`);
    } else if (createChoice.data && !createChoice.error && !createChoice.isLoading) {
      CmsSnackbarQueue.notify({
        title: `Choice created successfully.`,
        actions: [
          {
            label: 'VIEW',
            onClick: () => history.push(`/cms/quizzes/${quizId}/questions/${questionId}/choices/${createChoice.data!.id}`)
          }
        ]
      });
      history.push(`/cms/quizzes/${quizId}/questions/${questionId}/choices`);
    } else if (deleteChoice.data && !deleteChoice.error && !deleteChoice.isLoading) {
      CmsSnackbarQueue.notify({
        title: `Choice deleted successfully.`
      });
      history.push(`/cms/quizzes/${quizId}/questions/${questionId}/choices`);
    }
  }, [
    questionId,
    quizId,
    history,
    updateChoice.data,
    updateChoice.error,
    updateChoice.isLoading,
    createChoice.data,
    createChoice.error,
    createChoice.isLoading,
    deleteChoice.data,
    deleteChoice.error,
    deleteChoice.isLoading
  ]);

  const handleSubmit = async (
    values: ChoiceFormType,
    actions: FormikActions<
      | Choice
      | {
          id: string;
          choice: string;
          freetext: boolean;
          validateText: boolean;
          score: number;
          weighting: number;
          weight: number;
          question: Question | null;
        }
    >
  ) => {
    actions.setSubmitting(true);
    let newChoice = {
      choice: values.choice,
      freetext: values.freetext,
      validateText: values.validateText,
      score: values.score,
      weighting: values.weighting,
      weight: values.weight,
      question: values.question as Question
    };
    if (choiceId === 'new') {
      await createChoice.create(newChoice);
    } else {
      await updateChoice.update(newChoice);
    }
    actions.setSubmitting(false);
  };

  const handleDelete = async () => {
    await deleteChoice.choiceDelete();
  };

  const handleCancel = () => {
    history.goBack();
  };

  let initialValues;
  let ready = false;

  if (choiceId === 'new') {
    initialValues = {
      id: 'new',
      choice: '',
      freetext: false,
      validateText: false,
      score: 0,
      weighting: 1,
      weight: 1,
      question: getQuestion.data
    };
    ready = true;
  } else {
    initialValues = getError ? null : getChoice.data;
    ready = !getChoice.isLoading;
  }

  return (
    <Container>
      <BreadCrumbs
        crumbs={[
          { text: 'Quizzes', path: `/cms/quizzes` },
          { text: 'Questions', path: `/cms/quizzes/${quizId}/questions` },
          { text: 'Choices', path: `/cms/quizzes/${quizId}/questions/${questionId}/choices` },
          { text: `${choiceId === 'new' ? 'Add' : 'Edit'}`, path: ``, disabled: true }
        ]}
      />
      <TopBar>
        <Typography use='headline5'>
          Choice {getQuestion.data && getQuestion.data.question ? `- ${getQuestion.data.question}` : ''}
        </Typography>
      </TopBar>
      <StyledCard>
        {!ready && (
          <Spinner>
            <CircularProgress size='large' />
          </Spinner>
        )}
        {ready && !initialValues && <EmptyData onBackClick={handleCancel} isNoData />}
        {ready && initialValues && (
          <Formik
            initialValues={initialValues}
            validationSchema={ChoiceSchema}
            validateOnBlur={true}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, errors, touched, handleChange, handleBlur, isSubmitting, setFieldValue }) => (
              <FormContainer>
                <StyledTextField outlined name='id' label='ID' value={values.id} disabled />
                <StyledTextField
                  outlined
                  name='question'
                  label='Question Id'
                  value={(values.question && values.question.id) || ''}
                  disabled
                />
                <StyledTextField
                  outlined
                  type='text'
                  name='choice'
                  label='Choice'
                  onChange={handleChange}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('choice', values.choice.trim());
                    handleBlur(e);
                  }}
                  value={values.choice}
                  invalid={touched.choice && errors.choice}
                  helpText={{ persistent: true, validationMsg: true, children: touched.choice && errors.choice }}
                />
                <StyledTextField
                  outlined
                  type='number'
                  name='score'
                  label='Score'
                  step='any'
                  onKeyDown={(e: KeyboardEvent) => INVALID_DECIMAL_CHARS.includes(e.key) && e.preventDefault()}
                  onPaste={(e: ClipboardEvent) => {
                    handlePasteNumber(e, res => setFieldValue('score', res));
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.score}
                  invalid={touched.score && errors.score}
                  helpText={{ persistent: true, validationMsg: true, children: touched.score && errors.score }}
                />
                <StyledTextField
                  outlined
                  type='number'
                  name='weighting'
                  label='Weighting'
                  step='any'
                  onKeyDown={(e: KeyboardEvent) => INVALID_DECIMAL_CHARS.includes(e.key) && e.preventDefault()}
                  onPaste={(e: ClipboardEvent) => {
                    handlePasteNumber(e, res => setFieldValue('weighting', res));
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.weighting}
                  invalid={touched.weighting && errors.weighting}
                  helpText={{ persistent: true, validationMsg: true, children: touched.weighting && errors.weighting }}
                />
                <StyledTextField
                  outlined
                  type='number'
                  name='weight'
                  label='Weight'
                  onKeyDown={(e: KeyboardEvent) => INVALID_INTEGER_CHARS.includes(e.key) && e.preventDefault()}
                  onPaste={(e: ClipboardEvent) => {
                    handlePasteNumber(e, res => setFieldValue('weight', res));
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.weight}
                  invalid={touched.weight && errors.weight}
                  helpText={{ persistent: true, validationMsg: true, children: touched.weight && errors.weight }}
                />
                <Checkbox name='freetext' label='Freetext' onChange={handleChange} onBlur={handleBlur} checked={values.freetext} />
                <Checkbox
                  name='validateText'
                  label='Validate Text'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.validateText}
                />
                <ButtonContainer>
                  <Buttons>
                    <Button
                      unelevated
                      type='submit'
                      label='Save'
                      disabled={isSubmitting}
                      icon={isSubmitting ? <CircularProgress /> : null}
                    />
                    <Button theme='secondary' type='button' label='Cancel' onClick={handleCancel} />
                  </Buttons>
                  {choiceId !== 'new' && <DeleteButton type='button' label='Delete' onClick={() => setDialog(true)} />}
                </ButtonContainer>
              </FormContainer>
            )}
          </Formik>
        )}
      </StyledCard>
      <SimpleDialog
        title='Delete Choice'
        body='Are you sure you want to delete this choice?'
        open={dialog}
        onClose={async e => {
          if (e.detail.action === 'accept') {
            await handleDelete();
          }
          setDialog(false);
        }}
        acceptLabel='Delete'
      />
    </Container>
  );
}

export default withRouter(ChoiceEdit);
