import { useCallback } from 'react';

import Defaults from '../../constants/Defaults';
import { useApiGet, useApiMutation } from './Api.hooks';
import { ServiceSchedule } from '../../types/ServiceSchedule';
import { HttpMethod } from './Api';

export function useGetServiceSchedule(serviceScheduleId: string) {
  return useApiGet<ServiceSchedule | null>(null, 'service-schedules/' + serviceScheduleId);
}

export function useGetServiceSchedulesByPage() {
  const { get: originalGet, data, ...rest } = useApiGet<[ServiceSchedule[], number]>([[], 0], 'service-schedules');

  const get = useCallback(
    async (params?: { page?: number; query?: string; sort?: string; limit?: number; serviceId?: string }) => {
      const defaults = {
        page: 0,
        limit: Defaults.LIST_PAGE_SIZE
      };
      await originalGet({ ...defaults, ...params });
    },
    [originalGet]
  );

  const [responseData, count] = data;
  return { ...rest, data: responseData, count, get };
}

export type ServiceScheduleCreateDTO = { serviceId: string; scheduleId: number };

export function useCreateServiceSchedule() {
  const { mutate, ...rest } = useApiMutation<ServiceSchedule, ServiceScheduleCreateDTO>(null, HttpMethod.POST, 'service-schedules');

  const create = useCallback(
    async (serviceSchedules: ServiceScheduleCreateDTO) => {
      await mutate(serviceSchedules);
    },
    [mutate]
  );

  return { ...rest, create };
}

export function useDeleteServiceSchedule(serviceScheduleId: string) {
  const { mutate, ...rest } = useApiMutation<ServiceSchedule, null>(null, HttpMethod.DELETE, 'service-schedules/' + serviceScheduleId);

  const serviceScheduleDelete = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return { ...rest, serviceScheduleDelete };
}

export type ServiceScheduleCopyDTO = {
  originServiceId: string;
  copyServiceIds: string[];
};

export function useCopyServiceSchedules() {
  const { mutate, ...rest } = useApiMutation<ServiceSchedule, ServiceScheduleCopyDTO>(null, HttpMethod.POST, 'service-schedules/copy');

  const copy = useCallback(
    async (body: ServiceScheduleCopyDTO) => {
      await mutate(body);
    },
    [mutate]
  );

  return { ...rest, copy };
}
